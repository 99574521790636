import { Table } from 'antd'
import { useSelector } from 'react-redux'
import { selectUserList } from '../../../slices/userListSlice'
import { getColumns } from '../../../constants'
import './styles.css'

const UserAccount = () => {
  const userList = useSelector(selectUserList)

  return (
    <div className="user-account">
      <Table
        dataSource={userList}
        rowKey={(user) => user.user_id || user.login}
        columns={getColumns()}
        pagination={{
          position: ['bottomLeft'],
          hideOnSinglePage: true,
          defaultPageSize: '9',
        }}
      />
    </div>
  )
}

export default UserAccount
