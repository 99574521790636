import { List, Comment, Avatar } from 'antd'
import AdminIcon from '../../../assets/images/icons/adminImages/adminIcon.png'
import UserIcon from '../../../assets/images/icons/supportImages/supportIcon.png'
import ClientIcon from '../../../assets/images/icons/clientImages/clientIcon.png'
import { Role, roles } from '../../../constants'
import './styles.css'
import { getFileComment } from '../../../slices/applicationSlice'
import { useDispatch } from 'react-redux'

const Comments = ({ comments }) => {
  const dispatch = useDispatch()
  const role = Role()
  const isClientRole = role === roles.client || role === roles.clientAdmin
  const isAdminRole = role === roles.admin
  const Icon = isAdminRole ? AdminIcon : isClientRole ? ClientIcon : UserIcon //Выбор иконки в завимисоти от роли

  if (isClientRole) {
    comments.filter((el) => el.type !== 3)
  }

  return (
    <List header={`Комментарии: ${comments.length} `} itemLayout="horizontal">
      {comments.map((comment) => {
        return (
          <Comment
            className="comment"
            key={comment.rn}
            author={comment.pers_fio}
            datetime={comment.date_message}
            content={
              <>
                <p className="comment-text">{comment.message_txt}</p>
                {comment.file_name && (
                  <p
                    className="file-link"
                    onClick={() =>
                      dispatch(getFileComment(comment.file_id, comment.file_name))
                    }
                  >
                    Скачать прикрепленный файл {comment.file_name}
                  </p>
                )}
              </>
            }
            avatar={
              <Avatar
                src={comment.image ? `data:image/jpeg;base64,${comment.image}` : Icon}
              />
            }
          />
        )
      })}
    </List>
  )
}
export default Comments
