import { Form, Select, DatePicker } from 'antd'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { filterOption } from '../../../constants'
import {
  downloadStatistics,
  getStatistics,
  selectStatistic,
  setPeriod,
} from '../../../slices/statisctisSlice'
import {
  selectDepartments,
  selectProducts,
  selectTopics,
  setTopics,
  setDepartments,
  getTopics,
  getDepartments,
  selectClients,
  getClients,
  selectPersonal,
  setClients,
  selectCustomers,
} from '../../../slices/variableListSlice'
import 'moment/locale/ru'
import locale from 'antd/es/date-picker/locale/ru_RU'
import { rules } from '../../../constants/helpers'
import Urls from '../../../constants/urls'
const { RangePicker } = DatePicker
const { Option } = Select

const StatisticsForm = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const urls = Urls()
  const products = useSelector(selectProducts)
  const topics = useSelector(selectTopics)
  const departments = useSelector(selectDepartments)
  const clients = useSelector(selectClients)
  const personal = useSelector(selectPersonal)
  const customers = useSelector(selectCustomers)
  const statistic = useSelector(selectStatistic)

  const [user, setUser] = useState('')
  const [client, setClient] = useState('')
  const [dateStringStart, setDateStringStart] = useState('')
  const [dateStringFinish, setDateStringFinish] = useState('')
  const [topic, setTopic] = useState('')
  const [department, setDepartment] = useState('')
  const [customer, setCustomer] = useState('')
  const [productCode, setProductCode] = useState('')

  const onFinishBtn = () => {
    const obj = {
      P_DATE_FROM: dateStringStart,
      P_DATE_TO: dateStringFinish,
      P_PRODUCT: productCode,
      P_PERS_ID: user,
      P_TOPIC: topic,
      P_ORG: department,
      P_CLIENT_ID: client,
      P_CUSTOMER: customer,
    }
    dispatch(getStatistics(urls.GetStat, obj))
  }

  const onChangeProduct = (productCode) => {
    setProductCode(productCode)
    dispatch(getDepartments(urls.GetDepart, productCode))
    dispatch(getTopics(urls.GetTopics, productCode))
    dispatch(getClients(urls.GetClientList, productCode))
  }

  const onClearProduct = () => {
    setProductCode('')
    setTopic('')
    setDepartment('')
    setClient('')
    dispatch(setDepartments([]))
    dispatch(setTopics([]))
    dispatch(setClients([]))
    form.resetFields(['topic', 'department', 'client'])
  }

  const onChangeDate = (dateStrings) => {
    setDateStringStart(dateStrings[0])
    setDateStringFinish(dateStrings[1])
    dispatch(setPeriod(dateStrings))
  }

  const onStatDownload = (e) => {
    e.preventDefault()
    const obj = {
      P_DATE_FROM: dateStringStart,
      P_DATE_TO: dateStringFinish,
      P_PRODUCT: productCode,
      P_PERS_ID: user,
      P_TOPIC: topic,
      P_ORG: department,
      P_CLIENT_ID: client,
      P_CUSTOMER: customer,
    }
    dispatch(downloadStatistics(obj))
  }
  return (
    <Form
      className="create-user app-search-form"
      name="search-app"
      layout={'vertical'}
      onFinish={onFinishBtn}
      initialValues={{ product: productCode }}
      form={form}
      validateMessages={{
        required: 'Выберете период!',
      }}
    >
      <Form.Item style={{ width: '100%' }}>
        <h2>Статистика</h2>
      </Form.Item>
      <Form.Item className="form-item search-item" label="Специалист тех.поддержки">
        <Select
          allowClear={true}
          className="form-input"
          onSelect={(_, option) => setUser(option.value)}
          onClear={() => setUser('')}
          showSearch={true}
          filterOption={filterOption}
        >
          {personal.map((el) => {
            return (
              <Option key={el.personal_id} value={el.personal_id}>
                {el.pers_fio}
              </Option>
            )
          })}
        </Select>
      </Form.Item>
      <Form.Item className="form-item search-item" label="Заказчик" name="customer">
        <Select
          allowClear={true}
          className="form-input"
          onSelect={(_, option) => setCustomer(option.value)}
          onClear={() => setCustomer('')}
          showSearch={true}
          filterOption={filterOption}
        >
          {customers.map((el) => {
            return (
              <Option key={el.code} value={el.code}>
                {el.lex1}
              </Option>
            )
          })}
        </Select>
      </Form.Item>
      <Form.Item className="form-item search-item" label="Продукт" name="product">
        <Select
          className={'form-input app-info'}
          onSelect={(_, option) => onChangeProduct(option.value)}
          onClear={onClearProduct}
          allowClear={true}
          showSearch={true}
          filterOption={filterOption}
        >
          {products.map((el) => {
            return (
              <Option key={el.code} value={el.code}>
                {el.lex1}
              </Option>
            )
          })}
        </Select>
      </Form.Item>
      <Form.Item className="form-item search-item" label="Клиент" name="client">
        <Select
          allowClear={true}
          className="form-input"
          onSelect={(_, option) => setClient(option.value)}
          onClear={() => setClient('')}
          showSearch={true}
          filterOption={filterOption}
          notFoundContent="Данные не найдены"
        >
          {clients.map((el) => {
            return (
              <Option key={el.client_id} value={el.client_id}>
                {el.sname}
              </Option>
            )
          })}
        </Select>
      </Form.Item>
      <Form.Item className="form-item search-item" label="Тема" name="topic">
        <Select
          allowClear={true}
          className="form-input"
          onSelect={(_, option) => setTopic(option.value)}
          onClear={() => setTopic('')}
          showSearch={true}
          filterOption={filterOption}
          notFoundContent="Данные не найдены"
        >
          {topics.map((el) => {
            return (
              <Option key={el.code} value={el.code}>
                {el.lex1}
              </Option>
            )
          })}
        </Select>
      </Form.Item>
      <Form.Item
        className="form-item search-item"
        label="Подразделение, Организация"
        name="department"
      >
        <Select
          allowClear={true}
          className="form-input"
          onSelect={(_, option) => setDepartment(option.value)}
          onClear={() => setDepartment('')}
          notFoundContent="Данные не найдены"
        >
          {departments.map((el) => {
            return (
              <Option key={el.code} value={el.code}>
                {el.lex1}
              </Option>
            )
          })}
        </Select>
      </Form.Item>
      <Form.Item
        className="form-item search-item"
        label="Период (обязательно)"
        name="range-picker"
        rules={rules}
      >
        <RangePicker
          locale={locale}
          format={'DD.MM.YYYY'}
          onChange={(date, dateStrings) => onChangeDate(dateStrings)}
        />
      </Form.Item>
      <Form.Item style={{ width: '100%' }}>
        <button className="btn-submit">Сформировать отчет</button>
        {statistic.length !== 0 && (
          <button className="btn-submit" onClick={(e) => onStatDownload(e)}>
            Скачать отчет
          </button>
        )}
      </Form.Item>
    </Form>
  )
}

export default StatisticsForm
