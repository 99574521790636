import { Modal, Input, Form, Select } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectUser,
  getUserList,
  getClientList,
  searchUsers,
  selectIsSearch,
} from '../../../slices/userListSlice'
import useHttp from '../../../hooks/useHttp'
import { filterOption, Role, roles } from '../../../constants'
import { useEffect, useMemo, useState } from 'react'
import {
  addMultipleSelectString,
  filterHandbookValuesArray,
  filterHandbookValuesString,
  productsConcatenation,
  regionsToNumber,
  removeMultipleSelectString,
  rules,
  success,
} from '../../../constants/helpers'
import Urls from '../../../constants/urls'
import {
  getDepartments,
  selectDepartments,
  selectProducts,
  selectRegions,
} from '../../../slices/variableListSlice'
import { useUserInfo } from '../../../hooks/useUserInfo'
const { Option } = Select

const EditUserModal = ({ open, setOpen }) => {
  const { postRequest } = useHttp()
  const dispatch = useDispatch()
  const urls = Urls()

  const { clientProductsList, userRegions, userId } = useUserInfo()

  const role = Role()
  const isAdmin = useMemo(() => {
    return role === roles.admin
  }, [role])

  const products = useSelector(selectProducts)
  const regions = useSelector(selectRegions)
  const departments = useSelector(selectDepartments)
  const isUserSearched = useSelector(selectIsSearch)
  const editedUser = useSelector(selectUser)
  const isEditUserClient = useMemo(() => {
    return editedUser.role_id === roles.client || editedUser.role_id === roles.clientAdmin
  }, [editedUser])
  const editedUserId = useMemo(() => {
    return editedUser.user_id
  }, [editedUser])

  const editedUserProducts = useMemo(() => {
    return editedUser.product ? editedUser.product.split(',').map((el) => Number(el)) : []
  }, [editedUser])

  const editedUserMainProduct = useMemo(() => {
    return editedUserProducts[0]
  }, [editedUserProducts])

  const [surname, setSurname] = useState(editedUser.surname)
  const [userName, setUserName] = useState(editedUser.firstname)
  const [fatherName, setFatherName] = useState(editedUser.fathername)
  const [product, setProduct] = useState(editedUserMainProduct)
  const [addProductString, setAddProductString] = useState(editedUser.product)
  const [depart, setDepart] = useState(
    editedUser.depart_code ? editedUser.depart_code : null
  )
  const [selectedRegions, setSelectredRegions] = useState(
    editedUser?.regions ? regionsToNumber(editedUser.regions) : []
  )

  const filteredRegions = useMemo(() => {
    return regions.length ? filterHandbookValuesString(regions, userRegions) : []
  }, [regions, userRegions])

  const filteredProductsList = useMemo(() => {
    if (!clientProductsList.length) {
      return []
    } else if (products.length && clientProductsList.length) {
      return filterHandbookValuesArray(products, clientProductsList)
    }
  }, [products, clientProductsList])

  const addProducts = useMemo(() => {
    return filteredProductsList.filter((addProduct) => addProduct.code !== product)
  }, [filteredProductsList, product])

  const addProductsSelectValue = useMemo(() => {
    return addProductString
      ? addProductString
          .split(',')
          .map((el) => Number(el))
          .slice(1)
      : []
  }, [addProductString])

  //Колбэки
  const updUserCallback = (response) => {
    const data = response.data
    success(data[0].response)
    if (isUserSearched) {
      dispatch(searchUsers())
    } else {
      dispatch(
        isAdmin ? getUserList(urls.GetUsersList) : getClientList(urls.GetClientList)
      )
    }
  }

  //Слушатели событиый

  const onSaveChanges = () => {
    setOpen(false)
    const obj = {
      P_USER_ID: editedUserId,
      P_SURNAME: surname,
      P_FIRSTNAME: userName,
      P_FATHERNAME: fatherName,
      P_REGIONS: selectedRegions.join(','),
      P_PARENT_USER_ID: userId,
      P_PRODUCT: productsConcatenation(product, addProductsSelectValue),
      P_DEPART: isEditUserClient ? depart : null,
    }
    postRequest({
      url: urls.UpdUser,
      data: obj,
      callback: updUserCallback,
    })
  }

  const onAddProduct = (productCode) => {
    const productCodes = addMultipleSelectString(addProductString, productCode)
    setAddProductString(productCodes)
  }
  const onRemoveProduct = (productCode) => {
    const productCodes = removeMultipleSelectString(addProductString, productCode)
    setAddProductString(productCodes)
  }

  const onSelectRegions = (region) => {
    setSelectredRegions([...selectedRegions, region])
  }

  const onDeselectRegions = (region) => {
    const filteredRegions = selectedRegions.filter((value) => value !== region)
    setSelectredRegions(filteredRegions)
  }

  const onChangeProduct = (productCode) => {
    setProduct(productCode)
    setAddProductString('')
  }

  useEffect(() => {
    if (product) {
      dispatch(getDepartments(urls.GetDepart, product))
    }
  }, [])

  return (
    <Modal
      title="Редактирование имени пользователя"
      centered
      visible={open}
      onOk={onSaveChanges}
      onCancel={() => setOpen(false)}
      maskClosable={false}
      cancelText="Отменить"
      okText="Сохранить изменения"
    >
      <Form.Item label="Фамилия" style={{ margin: '0.5rem' }}>
        <Input value={surname} onChange={(e) => setSurname(e.target.value)} />
      </Form.Item>
      <Form.Item label="Имя" style={{ margin: '0.5rem' }}>
        <Input value={userName} onChange={(e) => setUserName(e.target.value)} />
      </Form.Item>
      <Form.Item label="Отчество" style={{ margin: '0.5rem' }}>
        <Input value={fatherName} onChange={(e) => setFatherName(e.target.value)} />
      </Form.Item>
      <Form.Item
        label="Основной продукт"
        className="user-form"
        validateTrigger="onSelect"
        rules={rules}
      >
        <Select
          className={'form-input app-info'}
          onSelect={(_, option) => onChangeProduct(option.value)}
          filterOption={filterOption}
          value={product}
        >
          {filteredProductsList.map((el) => {
            return (
              <Option key={el.code} value={el.code}>
                {el.lex1}
              </Option>
            )
          })}
        </Select>
      </Form.Item>
      <Form.Item
        label="Дополнительные продукты"
        className="user-form"
        validateTrigger="onSelect"
      >
        <Select
          className={'form-input app-info'}
          mode={'multiple'}
          onSelect={onAddProduct}
          showArrow={true}
          filterOption={filterOption}
          onDeselect={onRemoveProduct}
          value={addProductsSelectValue}
          disabled={!product}
        >
          {addProducts.map((el) => {
            return (
              <Option key={el.code} value={el.code}>
                {el.lex1}
              </Option>
            )
          })}
        </Select>
      </Form.Item>
      <Form.Item label="Регион" className="user-form" rules={rules}>
        <Select
          className={'form-input app-info'}
          onSelect={onSelectRegions}
          mode="multiple"
          onDeselect={onDeselectRegions}
          defaultValue={selectedRegions}
        >
          {filteredRegions.map((el) => {
            return (
              <Option key={el.code} value={el.code}>
                {el.lex1}
              </Option>
            )
          })}
        </Select>
      </Form.Item>
      {isEditUserClient && (
        <Form.Item
          className="user-form"
          label="Подразделение/Организация"
          validateTrigger="onSelect"
          rules={rules}
        >
          <Select
            className="form-input"
            onSelect={(_, option) => setDepart(option.value)}
            showSearch={true}
            filterOption={filterOption}
            notFoundContent="Данные не найдены"
            value={depart}
          >
            {departments.map((el) => {
              return (
                <Option key={el.code} value={el.code}>
                  {el.lex1}
                </Option>
              )
            })}
          </Select>
        </Form.Item>
      )}
    </Modal>
  )
}

export default EditUserModal
