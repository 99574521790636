import { Modal, Layout } from 'antd'
import { useSelector } from 'react-redux'
import { Role, roles } from '../../../constants'
import { selectAppComments, selectAppInfo } from '../../../slices/applicationSlice'
import Comments from '../Comments'
import CommentEditor from '../CommentEditor'
import './styles.css'
const { Footer, Content } = Layout

const CommentsWindow = ({ openComments, setOpenComments }) => {
  const comments = useSelector(selectAppComments)
  const appInfo = useSelector(selectAppInfo)
  const isAppFinished = appInfo.app_status === 4
  const role = Role()
  const isAdminPersonal = role === roles.admin || role === roles.personnelAdmin

  return (
    <Modal
      title={`Комментарии к заявке ${appInfo.app_num}`}
      centered
      visible={openComments}
      footer={null}
      onCancel={() => setOpenComments(false)}
      width="70%"
    >
      <Layout style={{ background: '#fff' }}>
        <Content
          className={isAdminPersonal ? 'comments-wrapper-admin' : 'comments-wrapper'}
        >
          <Comments comments={comments} />
        </Content>
        <Footer className="footer-comments">
          {isAppFinished ? (
            <h3 style={{ textAlign: 'center' }}>
              Заявка была завершена специалистом тех. поддержки
            </h3>
          ) : (
            !isAdminPersonal && <CommentEditor />
          )}
        </Footer>
      </Layout>
    </Modal>
  )
}

export default CommentsWindow
