import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  getClientList,
  getUserList,
  setSearchUserParams,
  setUserList,
} from '../../slices/userListSlice'
import { Layout } from 'antd'
import Header from '../../components/Header'
import UserHeader from '../../components/UserHeader'
import {
  getPosts,
  getProducts,
  getRegions,
  setDepartments,
  setPosts,
  setProducts,
} from '../../slices/variableListSlice'
import { Role, roles } from '../../constants'
import Urls from '../../constants/urls'
import CreateUser from '../../components/Admin/CreateUser'
import UserAccount from '../../components/Admin/UserAccount'

const { Footer, Content } = Layout

function AdminPage() {
  const role = Role()
  const isClientAdmin = role === roles.clientAdmin
  const dispatch = useDispatch()
  const urls = Urls()

  useEffect(() => {
    dispatch(getProducts())
    dispatch(getPosts())
    dispatch(getRegions())
    return function cleanUp() {
      dispatch(setPosts([]))
      dispatch(setUserList([]))
      dispatch(setProducts([]))
      dispatch(setDepartments([]))
      dispatch(setSearchUserParams({}))
    }
  }, [])

  useEffect(() => {
    if (isClientAdmin) {
      dispatch(getClientList(urls.GetClientList))
    } else {
      dispatch(getUserList(urls.GetUsersList))
    }
  }, [isClientAdmin])

  return (
    <Layout className="page-wrapper">
      <Header />
      <UserHeader />
      <Content className="wrapper" style={{ display: 'flex' }}>
        <CreateUser />
        <UserAccount />
      </Content>
      <Footer className="wrapper">© 2022 "Астерус" все права защищены</Footer>
    </Layout>
  )
}

export default AdminPage
