import { Button, Checkbox, Form, Input, Upload } from 'antd'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Role, roles } from '../../../constants'
import { error, success } from '../../../constants/helpers'
import Urls from '../../../constants/urls'
import useHttp from '../../../hooks/useHttp'
import { useUserInfo } from '../../../hooks/useUserInfo'
import {
  getAppComments,
  getAppInfo,
  getApplications,
  selectAppId,
  selectGetAppObj,
  selectGetAppUrl,
} from '../../../slices/applicationSlice'
import { setLoadingFalse, setLoadingTrue } from '../../../slices/variableListSlice'
import './styles.css'
const { TextArea } = Input

const CommentEditor = () => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const { postRequestHead } = useHttp()
  const { userClientId, userPersId } = useUserInfo()
  const role = Role()
  const isClientRole = role === roles.client || role === roles.clientAdmin

  const urls = Urls()
  const appId = useSelector(selectAppId)
  const getAppUrl = useSelector(selectGetAppUrl)
  const getAppObj = useSelector(selectGetAppObj)
  const [message, setMessage] = useState('')
  const [fileList, setFileList] = useState([])
  const [isClientInvisible, setIsClientInvisible] = useState(false)

  const uploadProps = {
    onRemove: () => {
      setFileList([])
    },
    beforeUpload: (file) => {
      setFileList([file])
      return false
    },
    fileList: fileList,
    maxCount: 1,
  }

  const onPasteFile = (evt) => {
    // Get the data of clipboard
    const clipboardItems = evt.clipboardData.items
    const items = [].slice.call(clipboardItems).filter((item) => {
      // Filter the image items only
      return item.type.indexOf('image') !== -1
    })
    if (items.length === 0) {
      return
    }

    const item = items[0]
    // Get the blob of image
    const blob = item.getAsFile()
    setFileList([blob])
  }

  //Колбэки

  const addCommentCallback = (resp) => {
    if (resp.tag) {
      error(resp.tag)
      setFileList([])
      form.resetFields()
    } else {
      success(resp.data[0].response)
      const objComments = isClientRole
        ? {
            p_app_id: appId,
            p_client_id: userClientId,
          }
        : {
            p_app_id: appId,
          }
      setMessage('')
      setFileList([])
      form.resetFields()
      dispatch(getAppComments(urls.GetMess, objComments))
      dispatch(getAppInfo(urls.GetAppInf))
      dispatch(getApplications(getAppUrl, getAppObj))
    }
    dispatch(setLoadingFalse())
  }

  //Слушатели событий
  const handleSubmit = async () => {
    if (message) {
      const formData = new FormData()
      formData.append('p_file', fileList[0])
      formData.append('p_client_id', isClientRole ? userClientId : '')
      formData.append('p_pers_id', isClientRole ? '' : userPersId)
      formData.append('p_message', message)
      formData.append('p_app_id', appId)
      formData.append('p_is_client_invisible', Number(isClientInvisible))
      dispatch(setLoadingTrue())
      postRequestHead({
        url: urls.AddMess,
        data: formData,
        callback: addCommentCallback,
      })
    } else error('Текст сообщения пуст!', 'Комментарии')
  }

  return (
    <Form form={form}>
      <Form.Item name="create-comment">
        <Input.Group>
          <div onPaste={onPasteFile}>
            <TextArea
              rows={10}
              onChange={(e) => setMessage(e.target.value)}
              value={message}
            />
            <Upload {...uploadProps}>
              <Button>Прикрепить файл</Button>
            </Upload>
          </div>
        </Input.Group>
      </Form.Item>
      <Form.Item>
        <div className="message-btns">
          <Button htmlType="submit" onClick={handleSubmit} type="primary">
            Отправить комментарий
          </Button>
          {!isClientRole && (
            <Checkbox onChange={() => setIsClientInvisible(!isClientInvisible)}>
              Скрыть для клиентов
            </Checkbox>
          )}
        </div>
      </Form.Item>
    </Form>
  )
}
export default CommentEditor
