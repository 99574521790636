import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import AdminPage from '../pages/AdminPage'
import Login from '../pages/Login'
import ApplicationsPage from '../pages/UserPage'
import UserSettingsPage from '../pages/UserSettingsPage'
import AppSearchPage from '../pages/AppSearchPage'
import HandbookPage from '../pages/HandbookPage'
import StatisticPage from '../pages/StatisticPage'
import PrivateRoute from './PrivateRoute'
import InformationPage from '../pages/InformationPage'

function Navigator() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/admin"
          element={
            <PrivateRoute>
              <AdminPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/applications"
          element={
            <PrivateRoute>
              <ApplicationsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/setting"
          element={
            <PrivateRoute>
              <UserSettingsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/app_search"
          element={
            <PrivateRoute>
              <AppSearchPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/handbook"
          element={
            <PrivateRoute>
              <HandbookPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/statistics"
          element={
            <PrivateRoute>
              <StatisticPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/information"
          element={
            <PrivateRoute>
              <InformationPage />
            </PrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  )
}

export default Navigator
