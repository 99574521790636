import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import Urls from '../constants/urls'

const initialState = {
  products: [],
  posts: [],
  roles: [],
  departments: [],
  topics: [],
  clients: [],
  personal: [],
  customers: [],
  regions: [],
  solutionMethods: [],
  loading: false,
}

const urls = Urls()

export const variableListSlice = createSlice({
  name: 'variablesList',
  initialState,
  reducers: {
    setProducts: (state, action) => {
      state.products = action.payload
    },
    setPosts: (state, action) => {
      state.posts = action.payload
    },
    setRoles: (state, action) => {
      state.roles = action.payload
    },
    setDepartments: (state, action) => {
      state.departments = action.payload
    },
    setTopics: (state, action) => {
      state.topics = action.payload
    },
    setClients: (state, action) => {
      state.clients = action.payload
    },
    setPersonal: (state, action) => {
      state.personal = action.payload
    },
    setCustomers: (state, action) => {
      state.customers = action.payload
    },
    setRegions: (state, action) => {
      state.regions = action.payload
    },
    setSolutionMethods: (state, action) => {
      state.solutionMethods = action.payload
    },
    setLoadingTrue: (state) => {
      state.loading = true
    },
    setLoadingFalse: (state) => {
      state.loading = false
    },
  },
})
//ACTIONS
export const {
  setProducts,
  setPosts,
  setRoles,
  setDepartments,
  setTopics,
  setClients,
  setPersonal,
  setLoadingTrue,
  setLoadingFalse,
  setCustomers,
  setRegions,
  setSolutionMethods,
} = variableListSlice.actions

// selectors
export const selectProducts = (state) => state.variableList.products
export const selectPosts = (state) => state.variableList.posts
export const selectRoles = (state) => state.variableList.roles
export const selectDepartments = (state) => state.variableList.departments

export const selectTopics = (state) => state.variableList.topics
export const selectClients = (state) => state.variableList.clients
export const selectPersonal = (state) => state.variableList.personal
export const selectLoading = (state) => state.variableList.loading
export const selectCustomers = (state) => state.variableList.customers
export const selectRegions = (state) => state.variableList.regions
export const selectSolutionMethods = (state) => state.variableList.solutionMethods

// thunk action creators
export const getProducts = () => {
  const url = urls.GetProducts
  return async (dispatch) => {
    try {
      const response = await axios.post(url, {})
      const appData = await response.data.data
      dispatch(setProducts(appData))
    } catch (err) {
      throw new Error(err)
    }
  }
}
export const getDepartments = (url, product) => {
  const obj = {
    p_product: product,
  }
  return async (dispatch) => {
    try {
      const response = await axios.post(url, obj)
      const appData = await response.data.data
      dispatch(setDepartments(appData))
    } catch (err) {
      throw new Error(err)
    }
  }
}
export const getPosts = () => {
  const url = urls.GetKlUni
  const obj = {
    P_KL_TYPE: 102,
  }

  return async (dispatch) => {
    try {
      const response = await axios.post(url, obj)
      const appData = await response.data.data
      dispatch(setPosts(appData))
    } catch (err) {
      throw new Error(err)
    }
  }
}
export const getCustomers = () => {
  const url = urls.GetCostumers
  return async (dispatch) => {
    try {
      const response = await axios.post(url, {})
      const appData = await response.data.data
      dispatch(setCustomers(appData))
    } catch (err) {
      throw new Error(err)
    }
  }
}
export const getTopics = (url, product) => {
  const obj = {
    p_product: product,
  }
  return async (dispatch) => {
    try {
      const response = await axios.post(url, obj)
      const appData = await response.data.data
      dispatch(setTopics(appData))
    } catch (err) {
      throw new Error(err)
    }
  }
}
export const getClients = (url, product) => {
  const obj = {
    p_product: product,
  }
  return async (dispatch) => {
    try {
      const response = await axios.post(url, obj)
      const appData = await response.data.data
      dispatch(setClients(appData))
    } catch (err) {
      throw new Error(err)
    }
  }
}
export const getPersonal = () => {
  const url = urls.GetPersSupport
  return async (dispatch) => {
    try {
      const response = await axios.post(url, {})
      const appData = await response.data.data
      dispatch(setPersonal(appData))
    } catch (err) {
      throw new Error(err)
    }
  }
}

export const getRegions = () => {
  const url = urls.GetKlUni
  const obj = {
    P_KL_TYPE: 103,
  }

  return async (dispatch) => {
    try {
      const response = await axios.post(url, obj)
      const appData = await response.data.data
      dispatch(setRegions(appData))
    } catch (err) {
      throw new Error(err)
    }
  }
}

export const getSolutionMethods = () => {
  const url = urls.GetKlUni
  const obj = {
    P_KL_TYPE: 302,
  }

  return async (dispatch) => {
    try {
      const response = await axios.post(url, obj)
      const appData = await response.data.data
      dispatch(setSolutionMethods(appData))
    } catch (err) {
      throw new Error(err)
    }
  }
}

export default variableListSlice.reducer
