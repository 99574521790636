import { useMemo } from 'react'
import { getCookie } from '../constants'

export const useUserInfo = () => {
  const cookie = getCookie()
  const clientProductsList = useMemo(
    () => cookie['p_product'].split(',').map((el) => Number(el)),
    [cookie]
  )
  const clientMainProduct = useMemo(() => Number(cookie['p_product_code']), [cookie])
  const userId = useMemo(() => cookie['p_user_id'], [cookie])

  const userClientId = useMemo(() => cookie['p_client_id'], [cookie])
  const userPersId = useMemo(() => cookie['p_pers_id'], [cookie])
  const userRegions = useMemo(() => cookie['p_regions'], [cookie])
  const userInfo = useMemo(() => cookie['p_info'], [cookie])

  return {
    clientProductsList,
    clientMainProduct,
    userId,
    userClientId,
    userPersId,
    userRegions,
    userInfo,
  }
}
