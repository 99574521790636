import Application from '../Application'
import { useSelector } from 'react-redux'
import {
  selectApplications,
  selectIsCreateApp,
  selectIsAppOpen,
} from '../../../slices/applicationSlice'
import './styles.css'
import { useEffect, useRef, useState } from 'react'
import ApplicationWork from '../ApplicationWork'
import CreateApplication from '../CreateApplication'

const ApplicationsField = ({ isSearch = false }) => {
  const applications = useSelector(selectApplications)
  const isCreateApp = useSelector(selectIsCreateApp)
  const isAppOpen = useSelector(selectIsAppOpen)
  const scrollRef = useRef(null)

  const [scrollPosition, setScrollPosition] = useState()

  useEffect(() => {
    if (!isAppOpen) {
      document.querySelector('.app-wrap').scrollTop = scrollPosition
    }
  }, [isAppOpen])

  return (
    <div
      className={`app-wrap ${isAppOpen ? 'app-wrap-flex' : 'overflow'}`}
      onScroll={(e) => setScrollPosition(e.currentTarget.scrollTop)}
    >
      {!isCreateApp && (
        <div className={isAppOpen ? 'overflow list width-30' : 'list width-80'}>
          <h2 className="application-header">Количество заявок: {applications.length}</h2>
          {applications.map((el) => {
            return (
              <Application
                key={el.app_num}
                date={el.app_date}
                header={el.app_num}
                product={el.product}
                topic={el.topic}
                client={el.sname || el.client_name}
                id={el.app_id}
                isComment={el.notif}
                personal={el.pers_fio}
                isTest={el.is_test}
                notification={el.notif}
                isAppOpen={isAppOpen}
                scrollRef={scrollRef}
              />
            )
          })}
        </div>
      )}
      {isAppOpen && <ApplicationWork isSearch={isSearch} />}
      {isCreateApp && <CreateApplication />}
    </div>
  )
}

export default ApplicationsField
