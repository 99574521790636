import { useSelector, useDispatch } from 'react-redux'
import {
  selectHandbookList,
  getHandbookInfo,
  setHanbookType,
  setIsEditOpen,
  setHandbookName,
  selectHandbookType,
  selectDependList,
  selectProductName,
  selectHandbookCode,
  getDependInfo,
  setDependType,
  setDependName,
  setIsDependOpen,
  setDependList,
  setHandbookCode,
} from '../../../slices/handbookSlice'
import { Col, Row, Button, Tooltip } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import Urls from '../../../constants/urls'

const HandbookList = ({ isDepend = false }) => {
  const dispatch = useDispatch()
  const urls = Urls()
  const handbookList = useSelector(isDepend ? selectDependList : selectHandbookList)
  const handbookType = useSelector(selectHandbookType)
  const productName = useSelector(selectProductName)
  const handbookCode = useSelector(selectHandbookCode)

  const onEditHandbook = (type, name) => {
    if (isDepend) {
      const obj = {
        P_TYPE: handbookType,
        P_CODE: handbookCode,
        P_TYPE_DEPEND: type,
      }
      dispatch(setDependName(name))
      dispatch(setIsDependOpen(true))
      dispatch(getDependInfo(urls.GetDependUni, obj))
      dispatch(setDependType(type))
    } else {
      const obj = {
        P_KL_TYPE: type,
      }
      dispatch(setHandbookName(name))
      dispatch(setIsEditOpen(true))
      dispatch(setIsDependOpen(false))
      dispatch(setHanbookType(type))
      dispatch(setHandbookCode(0))
      dispatch(setDependList([]))
      dispatch(getHandbookInfo(urls.GetKlUni, obj))
    }
  }

  return (
    <>
      {isDepend ? (
        <h2 style={{ maxWidth: '90%' }}>Зависимые справочники от {productName}</h2>
      ) : (
        <h2>Список справочников</h2>
      )}
      {handbookList.map((el) => {
        return (
          <Row key={el.type} gutter={16} style={{ padding: '4px 0' }}>
            <Col span={18} style={{ border: '1px solid #d1d1ff', padding: '5px' }}>
              {el.lex1}
            </Col>
            <Col span={4}>
              <Tooltip title="Редактирование справочника">
                <Button
                  shape="circle"
                  icon={<EditOutlined />}
                  style={{ backgroundColor: '#f0f2f5', color: '#5498c3' }}
                  onClick={() => onEditHandbook(el.type, el.lex1)}
                />
              </Tooltip>
            </Col>
          </Row>
        )
      })}
    </>
  )
}

export default HandbookList
