import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Layout, Row, Col } from 'antd'
import Header from '../../components/Header'
import UserHeader from '../../components/UserHeader'
import {
  getHandbooks,
  selectIsEditOpen,
  setHandbooks,
  setDependInfo,
  setDependList,
  selectDependList,
  setHanbookInfo,
  setIsEditOpen,
  setIsDependOpen,
  selectIsDependOpen,
} from '../../slices/handbookSlice'
import './styles.css'
import Urls from '../../constants/urls'
import HandbookEdit from '../../components/Hanbooks/HandbookEdit'
import HandbookList from '../../components/Hanbooks/HandbookList'

const { Footer, Content } = Layout

const HandbookPage = () => {
  const dispatch = useDispatch()
  const urls = Urls()
  const isEditOpen = useSelector(selectIsEditOpen)
  const dependList = useSelector(selectDependList)
  const isDependOpen = useSelector(selectIsDependOpen)

  useEffect(() => {
    dispatch(getHandbooks(urls.GetKlTypes))
    return function cleanUp() {
      dispatch(setHandbooks([]))
      dispatch(setHanbookInfo([]))
      dispatch(setIsEditOpen(false))
      dispatch(setIsDependOpen(false))
      dispatch(setDependInfo([]))
      dispatch(setDependList([]))
    }
  }, [])

  return (
    <Layout className="page-wrapper">
      <Header />
      <UserHeader />
      <Content className="wrapper">
        <Row>
          <Col span={8}>
            <HandbookList />
          </Col>
          {isEditOpen && (
            <Col
              className={
                dependList.length !== 0 ? 'handbook-elements--edit' : 'handbook-elements'
              }
              span={16}
            >
              <HandbookEdit />
            </Col>
          )}
        </Row>
        {dependList.length !== 0 && (
          <Row>
            <Col span={8}>
              <HandbookList isDepend={true} />
            </Col>
            {isDependOpen && (
              <Col span={16} className="handbook-elements--edit">
                <HandbookEdit isDepend={true} />
              </Col>
            )}
          </Row>
        )}
      </Content>
      <Footer className="wrapper">© 2022 "Астерус" все права защищены</Footer>
    </Layout>
  )
}

export default HandbookPage
