import React, { useEffect } from 'react'
import { Form, Input, Layout } from 'antd'
import Header from '../../components/Header'
import useHttp from '../../hooks/useHttp'
import { useNavigate } from 'react-router-dom'
import './styles.css'
import { error } from '../../constants/helpers'
import Urls from '../../constants/urls'
import { removeCookie, roles, setCookie } from '../../constants'
const { Footer, Content } = Layout

function Login() {
  const navigate = useNavigate()
  const urls = Urls()

  const { postRequest } = useHttp()

  const authCallback = (response) => {
    const data = response.data[0] || response
    const role = data.p_role
    const isAdmin = role === roles.admin || role === roles.clientAdmin
    if (data.message) {
      error(data.message)
    } else {
      setCookie('user', JSON.stringify(data))
      return navigate(isAdmin ? '/admin' : '/applications')
    }
  }

  useEffect(() => {
    removeCookie('user')
  }, [])

  const handleSubmit = (event) => {
    const data = event
    const login = data.username
    const password = data.password
    if (login && password) {
      const obj = {
        P_LOGIN: login,
        P_PASS: password,
      }
      postRequest({
        url: urls.AuthUser,
        data: obj,
        callback: authCallback,
      })
    } else {
      error('Введите логин и пароль!')
    }
  }

  return (
    <Layout className="wrapper page-wrapper-login" style={{ background: 'none' }}>
      <Header isLogin={true} />
      <Content>
        <Form
          className="loginContainer"
          name="authorization"
          onFinish={handleSubmit}
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 10,
          }}
          autoComplete="off"
        >
          <h2 style={{ textAlign: 'center' }}>Служба технической поддержки</h2>
          <Form.Item label="Логин" name="username" style={{ margin: '2rem 0' }}>
            <Input />
          </Form.Item>

          <Form.Item label="Пароль" name="password" style={{ margin: '2rem 0' }}>
            <Input.Password />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              push: 6,
            }}
          >
            <button className="btn-submit" type="submit">
              Вход
            </button>
          </Form.Item>
        </Form>
      </Content>
      <Footer className="login-footer">© 2022 "Астерус" все права защищены</Footer>
    </Layout>
  )
}

export default Login
