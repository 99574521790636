import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import Navigator from "./navigation";
import "./index.css";
import "antd/dist/antd.min.css";
import { store } from "./store";
import LoadingWindow from "./components/LoadingWindow";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <div className="main-wrapper">
        <Navigator />
        <LoadingWindow />
      </div>
    </Provider>
  </React.StrictMode>
);
