import { Form, Select, Input, DatePicker } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  getApplications,
  selectStatusesOnly,
  setAppId,
  setIsAppOpen,
} from '../../../slices/applicationSlice'
import './styles.css'
import {
  getDepartments,
  getTopics,
  selectDepartments,
  selectProducts,
  selectTopics,
  setDepartments,
  setTopics,
} from '../../../slices/variableListSlice'
import { getCookie, filterOption, Role, roles } from '../../../constants'
import 'moment/locale/ru'
import locale from 'antd/es/date-picker/locale/ru_RU'
import Urls from '../../../constants/urls'
import { useUserInfo } from '../../../hooks/useUserInfo'
import { filterHandbookValuesArray } from '../../../constants/helpers'

const { Option } = Select

const AppSearchForm = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const urls = Urls()
  const statusList = useSelector(selectStatusesOnly)
  const products = useSelector(selectProducts)
  const topics = useSelector(selectTopics)
  const departments = useSelector(selectDepartments)
  const { userClientId, userPersId, clientMainProduct, clientProductsList } =
    useUserInfo()
  const role = Role()
  const isClientRole = role === roles.client || role === roles.clientAdmin

  const [fioUser, setFioUser] = useState('')
  const [fioClient, setFioClient] = useState('')
  const [appNum, setAppNum] = useState('')
  const [description, setDescription] = useState('')
  const [dateString, setDateString] = useState('')
  const [status, setStatus] = useState('')
  const [topic, setTopic] = useState('')
  const [department, setDepartment] = useState('')
  const [productCode, setProductCode] = useState(isClientRole ? clientMainProduct : '')
  const filteredProducts = useMemo(() => {
    return isClientRole
      ? filterHandbookValuesArray(products, clientProductsList)
      : products
  }, [clientProductsList, isClientRole, products])

  const onSearchBtn = () => {
    if (isClientRole) {
      const obj = {
        P_CLIENT_ID: userClientId,
        P_APP_NUM: appNum,
        P_PRODUCT: productCode,
        P_TOPIC: topic,
        P_DESCRIPT: description,
        P_APP_DATE: dateString,
        P_APP_STATUS: status,
        P_FIO_CLIENT: fioClient,
        P_FIO_USER: fioUser,
        P_DEPART: department,
      }
      dispatch(getApplications(urls.ExtAppSearch, obj))
    } else {
      const obj = {
        P_PERS_ID: userPersId,
        P_APP_NUM: appNum,
        P_PRODUCT: productCode,
        P_TOPIC: topic,
        P_DESCRIPT: description,
        P_APP_DATE: dateString,
        P_APP_STATUS: status,
        P_FIO_CLIENT: fioClient,
        P_FIO_USER: fioUser,
        P_DEPART: department,
      }
      dispatch(getApplications(urls.ExtAppSearch, obj))
    }

    dispatch(setIsAppOpen(false))
    dispatch(setAppId(0))
  }

  const onChangeProduct = (productCode) => {
    setProductCode(productCode)
    dispatch(getDepartments(urls.GetDepart, productCode))
    dispatch(getTopics(urls.GetTopics, productCode))
  }

  const onClearProduct = () => {
    setProductCode('')
    setTopic('')
    setDepartment('')
    dispatch(setDepartments([]))
    dispatch(setTopics([]))
    form.resetFields(['topic', 'department'])
  }
  useEffect(() => {
    if (isClientRole) {
      dispatch(getDepartments(urls.GetDepart, productCode))
      dispatch(getTopics(urls.GetTopics, productCode))
    }
  }, [])

  return (
    <Form
      className="create-user app-search-form"
      name="search-app"
      layout={'vertical'}
      style={{ background: '#fff', padding: '1rem' }}
      onFinish={onSearchBtn}
      initialValues={{ product: productCode }}
      form={form}
    >
      <Form.Item style={{ width: '100%' }}>
        <h2>Расширенный поиск заявок</h2>
      </Form.Item>
      <Form.Item className="form-item search-item" label="ФИО Исполнителя">
        <Input
          value={fioUser}
          onChange={(e) => setFioUser(e.target.value)}
          className="form-input"
        />
      </Form.Item>
      <Form.Item className="form-item search-item" label="ФИО Клиента">
        <Input
          value={fioClient}
          onChange={(e) => setFioClient(e.target.value)}
          className="form-input"
        />
      </Form.Item>
      <Form.Item className="form-item search-item" label="Номер заявки">
        <Input
          className="form-input"
          value={appNum}
          onChange={(e) => setAppNum(e.target.value)}
        />
      </Form.Item>
      <Form.Item className="form-item search-item" label="Продукт" name="product">
        <Select
          className={`form-input app-info ${isClientRole && 'disabled-select'}`}
          onSelect={(_, option) => onChangeProduct(option.value)}
          onClear={onClearProduct}
          allowClear={true}
          showSearch={true}
          filterOption={filterOption}
        >
          {filteredProducts.map((el) => {
            return (
              <Option key={el.code} value={el.code}>
                {el.lex1}
              </Option>
            )
          })}
        </Select>
      </Form.Item>
      <Form.Item className="form-item search-item" label="Тема" name="topic">
        <Select
          allowClear={true}
          className="form-input"
          onSelect={(_, option) => setTopic(option.value)}
          onClear={() => setTopic('')}
          showSearch={true}
          filterOption={filterOption}
          notFoundContent="Данные не найдены"
        >
          {topics.map((el) => {
            return (
              <Option key={el.code} value={el.code}>
                {el.lex1}
              </Option>
            )
          })}
        </Select>
      </Form.Item>
      <Form.Item
        className="form-item search-item"
        label="Подразделение/Организация"
        name="department"
      >
        <Select
          allowClear={true}
          className="form-input"
          onSelect={(_, option) => setDepartment(option.value)}
          onClear={() => setDepartment('')}
          showSearch={true}
          filterOption={filterOption}
          notFoundContent="Данные не найдены"
        >
          {departments.map((el) => {
            return (
              <Option key={el.code} value={el.code}>
                {el.lex1}
              </Option>
            )
          })}
        </Select>
      </Form.Item>
      <Form.Item className="form-item search-item" label="Статус">
        <Select
          allowClear={true}
          className="form-input"
          onSelect={(_, option) => setStatus(option.value)}
          onClear={() => setStatus('')}
        >
          {statusList.map((el) => {
            return (
              <Option key={el.code} value={el.code}>
                {el.lex1}
              </Option>
            )
          })}
        </Select>
      </Form.Item>
      <Form.Item className="form-item search-item" label="Данные заявки">
        <Input
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="form-input"
        />
      </Form.Item>
      <Form.Item className="form-item search-item" label="Дата создания заявки">
        <DatePicker
          locale={locale}
          format={'DD.MM.YYYY'}
          onChange={(date, dateString) => setDateString(dateString)}
        />
      </Form.Item>
      <Form.Item style={{ width: '100%' }}>
        <button className="btn-submit">Поиск</button>
      </Form.Item>
    </Form>
  )
}
export default AppSearchForm
