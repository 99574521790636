import { Modal, Select, Form } from 'antd'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { filterOption } from '../../../constants'
import { error } from '../../../constants/helpers'
import Urls from '../../../constants/urls'
import useHttp from '../../../hooks/useHttp'
import { selectAppId } from '../../../slices/applicationSlice'
import { selectPersonal } from '../../../slices/variableListSlice'
const { Option } = Select

const ChangePersWindow = ({ openChangePers, setOpenChangePers, afterClose }) => {
  const urls = Urls()
  const personal = useSelector(selectPersonal)
  const appId = useSelector(selectAppId)
  const [user, setUser] = useState('')
  const { postRequest } = useHttp()

  const changePersCallback = (response) => {
    setOpenChangePers(false)
    afterClose(response)
  }

  const onChangePers = () => {
    if (user) {
      const obj = {
        P_APP_ID: appId,
        P_PERS_ID: user,
      }
      postRequest({
        url: urls.AppTransfer,
        data: obj,
        callback: changePersCallback,
      })
    } else {
      error('Выберите исполнителя!')
    }
  }
  return (
    <Modal
      title="Передать другому исполнителю"
      centered
      visible={openChangePers}
      onCancel={() => setOpenChangePers(false)}
      onOk={onChangePers}
      cancelText="Омена"
      afterClose={() => setUser('')}
    >
      <Form.Item
        style={{ flexDirection: 'column' }}
        labelAlign="left"
        label="Специалист тех.поддержки"
      >
        <Select
          allowClear={true}
          onSelect={(_, option) => setUser(option.value)}
          onClear={() => setUser('')}
          showSearch={true}
          filterOption={filterOption}
          value={user}
        >
          {personal.map((el) => {
            return (
              <Option key={el.personal_id} value={el.personal_id}>
                {el.pers_fio}
              </Option>
            )
          })}
        </Select>
      </Form.Item>
    </Modal>
  )
}

export default ChangePersWindow
