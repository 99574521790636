import React, { useEffect } from 'react'
import { Menu, Row, Col } from 'antd'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { selectUserName, getUserName } from '../../slices/userListSlice'
import './styles.css'
import AsterusIcon from '../../assets/images/icons/supportImages/asterus.png'
import AdminIcon from '../../assets/images/icons/adminImages/adminIcon.png'
import UserIcon from '../../assets/images/icons/supportImages/supportIcon.png'
import ClientIcon from '../../assets/images/icons/clientImages/clientIcon.png'
import { Role, roles } from '../../constants'
import Urls from '../../constants/urls'
import { useMenuItems } from '../../hooks/useMenuItems'
import { useUserInfo } from '../../hooks/useUserInfo'

const UserHeader = () => {
  const location = useLocation()
  const dispatch = useDispatch()

  const userName = useSelector(selectUserName)
  const urls = Urls()
  const { userId } = useUserInfo()
  const role = Role()

  const isClientRole = role === roles.client || role === roles.clientAdmin
  const isAdminRole = role === roles.admin
  const Icon = isAdminRole ? AdminIcon : isClientRole ? ClientIcon : UserIcon //Выбор иконки в завимисоти от роли
  const items = useMenuItems(role)

  useEffect(() => {
    dispatch(getUserName(urls.GetName, userId))
  }, [])
  return (
    <header className="user-header">
      <Row
        style={{
          height: '90px',
          padding: '1rem 0',
          color: '#000',
          justifyContent: 'space-between',
        }}
      >
        <Col span={5} style={{ textAlign: 'left', padding: '0.5rem' }}>
          <img src={AsterusIcon} alt="logo" /> Разработчик Астерус
        </Col>
        <Col span={14}>
          <Menu
            style={{ background: 'none', justifyContent: 'flex-end' }}
            mode="horizontal"
            defaultSelectedKeys={location.pathname.slice(1)}
            items={items}
          />
        </Col>
        <Col span={5} style={{ textAlign: 'center', alignSelf: 'center' }}>
          <div className={userName.image ? 'box' : ''}>
            <img
              src={userName.image ? `data:image/jpeg;base64,${userName.image}` : Icon}
              alt="user"
            />
            <p>{userName.sname}</p>
          </div>
        </Col>
      </Row>
    </header>
  )
}

export default UserHeader
