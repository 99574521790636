import React, { useState } from 'react'
import { Space, Button, Tooltip } from 'antd'
import { EditOutlined, CloseOutlined, ReloadOutlined } from '@ant-design/icons'
import useHttp from '../../../hooks/useHttp'
import EditUserModal from '../EditUserWindow'
import { useDispatch } from 'react-redux'
import { setUser, getUserList, getClientList } from '../../../slices/userListSlice'
import { Role, roles } from '../../../constants'
import { confirm, success } from '../../../constants/helpers'
import Urls from '../../../constants/urls'
import { useUserInfo } from '../../../hooks/useUserInfo'

const UserEditButtons = ({ editedUserId }) => {
  const dispatch = useDispatch()
  const urls = Urls()
  const { postRequest } = useHttp()
  const [open, setOpen] = useState(false)
  const { userId } = useUserInfo()
  const role = Role()
  const isAdmin = role === roles.admin

  //Колбэки
  const deleteUserCallback = (response) => {
    success(response.data[0].response)
    dispatch(isAdmin ? getUserList(urls.GetUsersList) : getClientList(urls.GetClientList))
  }
  const resetCallback = (response) => {
    success(response.data[0].response)
  }
  //Слушатели событий
  const onEditButton = (editedUserId) => {
    dispatch(setUser(editedUserId))
    setOpen(true)
  }

  const onDeleteBtn = (editedUserId) => {
    const title = 'Вы действительно хотите удалить пользователя?'
    const obj = {
      P_USER_ID: editedUserId,
      P_PARENT_USER_ID: userId,
    }
    const url = urls.DELUSER
    confirm(title, obj, url, deleteUserCallback, postRequest)
  }

  const onReset = (editedUserId) => {
    const title = 'Вы действительно хотите сбросить пароль?'
    const obj = {
      P_USER_ID: editedUserId,
      P_PASS: 123,
    }
    const url = urls.PasswordChange
    confirm(title, obj, url, resetCallback, postRequest)
  }
  return (
    <>
      <Space size="small">
        <Tooltip title="Удаление пользователя">
          <Button
            shape="circle"
            icon={<CloseOutlined />}
            style={{ backgroundColor: '#f0f2f5', color: '#5498c3' }}
            onClick={() => onDeleteBtn(editedUserId)}
          />
        </Tooltip>
        <Tooltip title="Редактирование фио пользователя">
          <Button
            shape="circle"
            icon={<EditOutlined />}
            style={{ backgroundColor: '#f0f2f5', color: '#5498c3' }}
            onClick={() => onEditButton(editedUserId)}
          />
        </Tooltip>
        <Tooltip title="Сброс пароля пользователя">
          <Button
            shape="circle"
            icon={<ReloadOutlined />}
            style={{ backgroundColor: '#f0f2f5', color: '#5498c3' }}
            onClick={() => onReset(editedUserId)}
          />
        </Tooltip>
      </Space>
      {open && <EditUserModal open={open} setOpen={setOpen} />}
    </>
  )
}
export default UserEditButtons
