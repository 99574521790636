import { useEffect } from 'react'
import { Layout } from 'antd'
import Header from '../../components/Header'
import UserHeader from '../../components/UserHeader'
import UserSettingForm from '../../components/UserSettingForm'
import { useDispatch, useSelector } from 'react-redux'
import { selectUser, getUserForUpd, setUser } from '../../slices/settingSlice'
import { getCookie, Role, roles } from '../../constants'
import {
  getDepartments,
  getPosts,
  setDepartments,
  setPosts,
} from '../../slices/variableListSlice'
import Urls from '../../constants/urls'
import { useUserInfo } from '../../hooks/useUserInfo'

const { Footer, Content } = Layout

const UserSettingsPage = () => {
  const dispatch = useDispatch()
  const urls = Urls()
  const { userClientId, clientMainProduct, userPersId } = useUserInfo()
  const role = Role()
  const isClientRole = role === roles.client || role === roles.clientAdmin
  const user = useSelector(selectUser)

  useEffect(() => {
    if (isClientRole) {
      const obj = { P_CLIENT_ID: userClientId }
      dispatch(getUserForUpd(urls.GetClientForUPD, obj))
      dispatch(getDepartments(urls.GetDepart, clientMainProduct))
      dispatch(getPosts())
    } else {
      const obj = { P_PERS_ID: userPersId }
      dispatch(getUserForUpd(urls.GetPersForUpd, obj))
    }
    return function cleanUp() {
      dispatch(setUser({}))
      dispatch(setDepartments([]))
      dispatch(setPosts([]))
    }
  }, [])
  return (
    <Layout className="page-wrapper">
      <Header />
      <UserHeader />
      <Content className="wrapper">
        {Object.keys(user).length > 0 && <UserSettingForm user={user} role={role} />}
      </Content>
      <Footer className="wrapper">© 2022 "Астерус" все права защищены</Footer>
    </Layout>
  )
}

export default UserSettingsPage
