import { Layout } from 'antd'
import Header from '../../components/Header'
import UserHeader from '../../components/UserHeader'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getStatuses, setStatuses } from '../../slices/applicationSlice'
import {
  getProducts,
  setProducts,
  setDepartments,
  setClients,
  setTopics,
  getPersonal,
  setPersonal,
  getCustomers,
  setCustomers,
} from '../../slices/variableListSlice'
import { selectStatistic, setPeriod, setStatistic } from '../../slices/statisctisSlice'
import Urls from '../../constants/urls'
import StatisticsTable from '../../components/Statistics/StatisticsTable'
import StatisticsForm from '../../components/Statistics/StatisticsForm'

const { Footer, Content } = Layout

const StatisticPage = () => {
  const dispatch = useDispatch()
  const urls = Urls()
  const statistic = useSelector(selectStatistic)
  useEffect(() => {
    dispatch(getStatuses(urls.GetStatList))
    dispatch(getProducts())
    dispatch(getPersonal())
    dispatch(getCustomers())
    return function cleanUp() {
      dispatch(setProducts([]))
      dispatch(setCustomers([]))
      dispatch(setDepartments([]))
      dispatch(setTopics([]))
      dispatch(setClients([]))
      dispatch(setStatuses([]))
      dispatch(setPersonal([]))
      dispatch(setPeriod([]))
      dispatch(setStatistic([]))
    }
  }, [])
  return (
    <Layout className="page-wrapper">
      <Header />
      <UserHeader />
      <Content className="wrapper">
        <StatisticsForm />
        {statistic.length !== 0 && <StatisticsTable />}
      </Content>
      <Footer className="wrapper">© 2022 "Астерус" все права защищены</Footer>
    </Layout>
  )
}

export default StatisticPage
