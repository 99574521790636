import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { Token } from '../constants'
import { setLoadingFalse, setLoadingTrue } from './variableListSlice'

export const token = Token()

const initialState = {
  user: {},
}

export const settingSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
    },
  },
})
export const { setUser } = settingSlice.actions

// thunk action creators
export const getUserForUpd = (url, obj) => {
  return async (dispatch) => {
    try {
      dispatch(setLoadingTrue())
      const response = await axios.post(url, obj)
      const appData = await response.data.data[0]
      dispatch(setUser(appData))
      dispatch(setLoadingFalse())
    } catch (err) {
      dispatch(setLoadingFalse())
      throw new Error(err)
    }
  }
}

export const selectUser = (state) => state.settings.user

export default settingSlice.reducer
