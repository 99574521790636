import { Modal, Input, Form } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import useHttp from '../../../hooks/useHttp'
import { useState } from 'react'
import {
  selectHandbookType,
  getHandbookInfo,
  selectDependType,
  getDependInfo,
  selectHandbook,
  selectDependCode,
  selectHandbookCodeForEdit,
  selectHandbookCode,
} from '../../../slices/handbookSlice'
import { success } from '../../../constants/helpers'
import Urls from '../../../constants/urls'
import { useUserInfo } from '../../../hooks/useUserInfo'

const EditHandbookModal = ({ open, setOpen, isDepend, isEdit, setIsEdit }) => {
  const dispatch = useDispatch()
  const { userId } = useUserInfo()
  const { postRequest } = useHttp()
  const urls = Urls()
  const handbookCodeForEdit = useSelector(selectHandbookCodeForEdit)
  const handbookCode = useSelector(selectHandbookCode)

  const dependCode = useSelector(selectDependCode)

  const dependType = useSelector(selectDependType)
  const handbook = useSelector(selectHandbook)
  const isTopic = isDepend && dependType === 202

  const handbookType = useSelector(selectHandbookType)
  const description =
    isEdit && handbookType === 201
      ? handbook.lex1.slice(0, handbook.lex1.indexOf('('))
      : handbook.lex1
  const [value, setValue] = useState(isEdit ? description : '')
  const [requiredParam, setRequiredParam] = useState(handbook.lex3 || '')

  const editItemCallback = (resp) => {
    if (isDepend) {
      const obj = {
        P_TYPE: handbookType,
        P_CODE: handbookCode,
        P_TYPE_DEPEND: dependType,
      }
      dispatch(getDependInfo(urls.GetDependUni, obj))
    } else {
      const obj = {
        P_KL_TYPE: handbookType,
      }
      dispatch(getHandbookInfo(urls.GetKlUni, obj))
    }
    success(resp.data[0].response, 'Сохранено!')
  }

  const onSaveChanges = () => {
    if (isEdit) {
      const obj = {
        P_USER_ID: userId,
        P_TYPE: isDepend ? dependType : handbookType,
        P_CODE: isDepend ? dependCode : handbookCodeForEdit,
        P_LEX1: value,
        P_LEX3: requiredParam,
      }
      postRequest({
        url: urls.UpdKlUni,
        data: obj,
        callback: editItemCallback,
      })
    } else {
      const obj = isDepend
        ? {
            P_USER_ID: userId,
            P_TYPE: dependType,
            P_LEX1: value,
            P_LEX3: requiredParam,
            P_TYPE_DEPEND: handbookType,
            P_CODE_DEPEND: handbookCode,
          }
        : {
            P_USER_ID: userId,
            P_TYPE: handbookType,
            P_LEX1: value,
          }
      postRequest({
        url: urls.AddKlUni,
        data: obj,
        callback: editItemCallback,
      })
    }
    setIsEdit(false)
    setOpen(false)
    setRequiredParam('')
    setValue('')
  }
  const onCancel = () => {
    setIsEdit(false)
    setOpen(false)
    setRequiredParam('')
    setValue('')
  }

  return (
    <Modal
      title={
        isEdit ? 'Редактирование элемента справочника' : 'Добавление элемента справочника'
      }
      centered
      visible={open}
      onOk={onSaveChanges}
      onCancel={onCancel}
      cancelText="Отменить"
      okText={isEdit ? 'Сохранить' : 'Добавить'}
    >
      <Form.Item label="Значение" style={{ margin: '0.5rem' }}>
        <Input value={value} onChange={(e) => setValue(e.target.value)} />
      </Form.Item>
      {isTopic && (
        <Form.Item label="Обязательный параметр" style={{ margin: '0.5rem' }}>
          <Input
            value={requiredParam}
            onChange={(e) => setRequiredParam(e.target.value)}
          />
        </Form.Item>
      )}
    </Modal>
  )
}

export default EditHandbookModal
