import { getBase64 } from '../../constants'

const UploadAndDisplayImage = ({ selectedImage, setSelectedImage }) => {
  const encodeCallback = (result) => {
    const baseString = result.split(',')[1]
    setSelectedImage(baseString)
  }

  return (
    <div>
      <h1>Загрузить Фото</h1>
      {selectedImage && (
        <div>
          <img
            alt="not found"
            width={'400px'}
            src={`data:image/jpeg;base64,${selectedImage}`}
          />
          <br />
          <button onClick={() => setSelectedImage('')}>Удалить фото</button>
        </div>
      )}
      <input
        type="file"
        name="myImage"
        className="form-input"
        onChange={(event) => {
          getBase64(event.target.files[0], encodeCallback)
        }}
      />
    </div>
  )
}

export default UploadAndDisplayImage
