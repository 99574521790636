import UserEditButtons from '../components/Admin/UserEditButtons'

export const setCookie = (name, value, options = {}) => {
  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString()
  }

  let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value)

  for (let optionKey in options) {
    updatedCookie += '; ' + optionKey
    let optionValue = options[optionKey]
    if (optionValue !== true) {
      updatedCookie += '=' + optionValue
    }
  }

  document.cookie = updatedCookie
}

export const getCookie = () => {
  let matches = document.cookie.match(
    new RegExp(
      '(?:^|; )' + 'user'.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
    )
  )
  return matches ? JSON.parse(decodeURIComponent(matches[1])) : undefined
}

export const removeCookie = (name) => {
  setCookie(name, '', {
    'max-age': -1,
  })
}

export const getBase64 = (file, cb) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = function () {
    cb(reader.result)
  }
  reader.onerror = function (error) {
    error(error)
    console.log('Error: ', error)
  }
}

export const Token = () => {
  const cookie = getCookie()
  const token = (cookie && cookie['password']) || ''
  return token
}

export const Role = () => {
  const cookie = getCookie()
  if (cookie) {
    const role = cookie['p_role']
    return role
  }
}

export const filterOption = (value, option) =>
  option.children.toLowerCase().includes(value.toLowerCase())

export const roles = {
  admin: 1,
  client: 2,
  support: 3,
  tester: 4,
  clientAdmin: 5,
  personnelAdmin: 6,
}

export const notifications = {
  clientAnswered: 1,
  supportAnswered: 2,
}

export const SOLUTIONS_METHODS = {
  explain: 1,
  script: 2,
  specialist: 3,
}

export const APLICATION_STATUSES = {
  new: 1,
  inwork: 2,
  prefinished: 3,
  finished: 4,
}

export const getColumns = () => {
  const isClientAdmin = Role() === 5
  return isClientAdmin
    ? [
        {
          title: 'Логин',
          dataIndex: 'login',
          key: 'login',
          className: 'table-item',
        },
        {
          title: 'ФИО',
          dataIndex: 'sname',
          key: 'sname',
          className: 'table-item',
        },
        {
          title: 'Роль',
          dataIndex: 'role',
          key: 'role',
          className: 'table-item',
        },
        {
          title: 'Подразделение/Организация',
          dataIndex: 'depart',
          key: 'depart',
          className: 'table-item',
        },
        {
          title: 'Должность',
          dataIndex: 'position',
          key: 'position',
          className: 'table-item',
        },
        {
          title: 'Редактировать',
          key: 'action',
          dataIndex: 'user_id',
          render: (userId) => <UserEditButtons editedUserId={userId} />,
        },
      ]
    : [
        {
          title: 'Логин',
          dataIndex: 'login',
          key: 'login',
          className: 'table-item',
        },
        {
          title: 'ФИО',
          dataIndex: 'sname',
          key: 'sname',
          className: 'table-item',
        },
        {
          title: 'Роль',
          dataIndex: 'role',
          key: 'role',
          className: 'table-item',
        },
        {
          title: 'Редактировать',
          key: 'action',
          dataIndex: 'user_id',
          render: (userId, record) => <UserEditButtons editedUserId={userId} />,
        },
      ]
}
