import Axios from 'axios'
import { getCookie } from '../constants'

export default function useHttp() {
  const cookie = getCookie()
  const token = (cookie && cookie['password']) || ''

  const config = {
    headers: {
      'X-Token': token,
    },
  }

  const postRequest = ({ url, data = {}, callback }) => {
    Axios.post(url, data, config)
      .then((response) => callback(response['data']))
      .catch((err) => console.log(err))
  }

  const postRequestHead = ({ url, data = {}, info = {}, callback }) => {
    Axios.post(url, data, {
      headers: {
        ...info,
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => callback(response['data']))
      .catch((err) => console.log(err))
  }

  const updateUser = async ({ selectedImage, url, info, callback }) => {
    const blob = await fetch(`data:image/jpeg;base64,${selectedImage}`).then((res) =>
      res.blob()
    )
    const formData = new FormData()
    formData.append('avatar.png', blob)
    postRequestHead({
      url: url,
      data: formData,
      info: info,
      callback: callback,
    })
  }

  const getRequest = ({ url, callback }) => {
    Axios.get(url, config).then((response) => callback(response))
  }

  return {
    postRequest,
    getRequest,
    postRequestHead,
    updateUser,
  }
}
