import { Layout } from 'antd'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Header from '../../components/Header'
import TemplatesForApplications from '../../components/Information/TemplatesForApplications'
import UserHeader from '../../components/UserHeader'
import Urls from '../../constants/urls'
import { getInformationTemplates, setAppComments } from '../../slices/applicationSlice'

const { Footer, Content } = Layout

function InformationPage() {
  const urls = Urls()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getInformationTemplates(urls.GetMess))
    return () => dispatch(setAppComments([]))
  }, [dispatch, urls])
  return (
    <Layout className="page-wrapper">
      <Header />
      <UserHeader />
      <Content className="wrapper">
        <h2>Страница информации по продуктам тех поддержки</h2>
        <TemplatesForApplications />
      </Content>
      <Footer className="wrapper">© 2022 "Астерус" все права защищены</Footer>
    </Layout>
  )
}

export default InformationPage
