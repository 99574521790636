import { Layout } from 'antd'
import Header from '../../components/Header'
import UserHeader from '../../components/UserHeader'
import { useDispatch } from 'react-redux'
import {
  setAppId,
  setIsAppOpen,
  setApplications,
  getStatusesOnly,
  setStatusesOnly,
} from '../../slices/applicationSlice'
import {
  getPersonal,
  getProducts,
  getSolutionMethods,
  setDepartments,
  setPersonal,
  setProducts,
  setSolutionMethods,
  setTopics,
} from '../../slices/variableListSlice'
import { useEffect } from 'react'
import ApplicationsField from '../../components/Applications/ApplicationsField'
import AppSearchForm from '../../components/ApplicationsSearch/AppSearchForm'

const { Footer, Content } = Layout

const AppSearchPage = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getStatusesOnly())
    dispatch(getProducts())
    dispatch(getPersonal())
    dispatch(getSolutionMethods())
    return function cleanUp() {
      dispatch(setApplications([]))
      dispatch(setIsAppOpen(false))
      dispatch(setAppId(0))
      dispatch(setStatusesOnly([]))
      dispatch(setProducts([]))
      dispatch(setDepartments([]))
      dispatch(setTopics([]))
      dispatch(setPersonal([]))
      dispatch(setSolutionMethods([]))
    }
  }, [])
  return (
    <Layout className="page-wrapper">
      <Header />
      <UserHeader />
      <Content className="wrapper">
        <AppSearchForm />
        <ApplicationsField isSearch={true} />
      </Content>
      <Footer className="wrapper">© 2022 "Астерус" все права защищены</Footer>
    </Layout>
  )
}

export default AppSearchPage
