import React from 'react'
import { Row, Col } from 'antd'
import { useNavigate } from 'react-router-dom'
import './styles.css'
import ExitIcon from '../../assets/images/icons/adminImages/exit_logout_sign-out_icon.png'
import LogoImg from '../../assets/images/icons/loginImages/logo_asterus.png'
import PhoneIcon from '../../assets/images/icons/loginImages/phone_connection_icon.png'

const Header = ({ isLogin = false }) => {
  const navigate = useNavigate()

  const onExit = () => {
    navigate('/', { replace: true })
  }
  return (
    <header className={isLogin ? 'login-header' : 'header'}>
      <Row
        style={{
          padding: '1rem 0',
          color: '#fff',
          width: '90%',
          margin: '0 auto',
        }}
      >
        {isLogin && (
          <Col span={6}>
            <img src={LogoImg} alt="logo" /> Разработчик Астерус
          </Col>
        )}
          <Col span={6}>
              <img src={PhoneIcon} alt="phone" />
              {/*+375 (17) 3376089 КСПД*/}
              +375 (17) 3376089 <br/> Сети. Обслуживание сети
          </Col>
        <Col span={6} align={isLogin ? 'right' : 'left'} >
          <img src={PhoneIcon} alt="phone" />
          +375 (17) 2003420
            <img src={PhoneIcon} alt="phone"  style={{paddingLeft: '15px'}} />
            +375 (17) 2003429<br/>
            ПРИС.Техническая поддержка
        </Col>
        <Col span={6} align={isLogin ? 'center' : 'left'}>
          {/*<img src={PhoneIcon} alt="phone" />*/}
          {/*+375 (17) 2003429*/}
        </Col>

        {!isLogin && (
          <Col span={6} align="center" onClick={onExit} style={{ cursor: 'pointer' }}>
            <img src={ExitIcon} alt="exit" />
            Выход
          </Col>
        )}
      </Row>
    </header>
  )
}

export default Header
