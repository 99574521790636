import { Link } from 'react-router-dom'
import { roles } from '../constants'

export const useMenuItems = (role) => {
  const isClientRole = role === roles.client || role === roles.clientAdmin
  const isClientAdmin = role === roles.clientAdmin
  const isAdminRole = role === roles.admin

  const menuItemsAdmin = [
    {
      label: <Link to={'/app_search'}>Поиск по заявкам</Link>,
      key: 'app_search',
      style: { borderBottom: '2px solid' },
    },
    {
      label: <Link to="/admin">Создание пользователей</Link>,
      key: 'admin',
      style: { borderBottom: '2px solid' },
    },
    {
      label: <Link to="/handbook">Ведение справочников</Link>,
      key: 'handbook',
      style: { borderBottom: '2px solid' },
    },
    {
      label: <Link to="/statistics">Статистика</Link>,
      key: 'statistics',
      style: { borderBottom: '2px solid' },
    },
    {
      label: <Link to="/information">Информация</Link>,
      key: 'information',
      style: { borderBottom: '2px solid' },
    },
  ]

  const menuItemsUser = [
    {
      label: <Link to={'/applications'}> Мои заявки</Link>,
      key: 'applications',
      style: { borderBottom: '2px solid' },
    },
    {
      label: <Link to={'/setting'}>Настройки</Link>,
      key: 'setting',
      style: { borderBottom: '2px solid' },
    },
    {
      label: <Link to={'/app_search'}>Расширенный поиск</Link>,
      key: 'app_search',
      style: { borderBottom: '2px solid' },
    },
    {
      label: <Link to="/information">Информация</Link>,
      key: 'information',
      style: { borderBottom: '2px solid' },
    },
    isClientRole
      ? null
      : {
          label: <Link to="/statistics">Статистика</Link>,
          key: 'statistics',
          style: { borderBottom: '2px solid' },
        },
  ]
  const menuItemClientAdmin = [
    {
      label: <Link to={'/applications'}>Работа с заявками</Link>,
      key: 'applications',
      style: { borderBottom: '2px solid' },
    },
    {
      label: <Link to={'/setting'}>Настройки</Link>,
      key: 'setting',
      style: { borderBottom: '2px solid' },
    },
    {
      label: <Link to={'/app_search'}>Поиск по заявкам</Link>,
      key: 'app_search',
      style: { borderBottom: '2px solid' },
    },
    {
      label: <Link to="/admin">Создание пользователей</Link>,
      key: 'admin',
      style: { borderBottom: '2px solid' },
    },
    {
      label: <Link to="/information">Информация</Link>,
      key: 'information',
      style: { borderBottom: '2px solid' },
    },
  ]

  if (isAdminRole) {
    return menuItemsAdmin
  } else if (isClientAdmin) {
    return menuItemClientAdmin
  } else {
    return menuItemsUser
  }
}
