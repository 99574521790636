import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import './styles.css'
import { useSelector } from 'react-redux'
import { selectLoading } from '../../slices/variableListSlice'

const LoadingWindow = () => {
  const loading = useSelector(selectLoading)
  const loadingIcon = (
    <LoadingOutlined
      style={{
        fontSize: 100,
        color: '#555aff',
      }}
      spin
    />
  )

  return (
    <>
      {loading && (
        <div className="loading-modal-wrapper">
          <div className="loading-modal">
            <Spin className="loading-spinner" indicator={loadingIcon} />
          </div>
        </div>
      )}
    </>
  )
}
export default LoadingWindow
