import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
  handbooks: [],
  dependList: [],
  handbook: {},
  handbookInfo: [],
  dependInfo: [],
  handbookType: 0,
  dependType: 0,
  handbookName: '',
  productName: '',
  dependName: '',
  handbookCode: 0,
  handbookCodeForEdit: 0,
  dependCode: 0,
  isEditOpen: false,
  isDependOpen: false,
}

export const handbookSlice = createSlice({
  name: 'handbook',
  initialState,
  reducers: {
    setHandbooks: (state, action) => {
      state.handbooks = action.payload
    },
    setDependList: (state, action) => {
      state.dependList = action.payload
    },
    setHandbook: (state, action) => {
      state.handbook =
        action.payload.type === 'depend'
          ? state.dependInfo.filter((el) => el.code === action.payload.code)[0]
          : state.handbookInfo.filter((el) => el.code === action.payload.code)[0]
    },
    setHanbookInfo: (state, action) => {
      state.handbookInfo = action.payload.sort((a, b) => b.active - a.active)
    },
    setDependInfo: (state, action) => {
      state.dependInfo = action.payload.sort((a, b) => b.active - a.active)
    },
    setHanbookType: (state, action) => {
      state.handbookType = action.payload
    },
    setDependType: (state, action) => {
      state.dependType = action.payload
    },
    setDependName: (state, action) => {
      state.dependName = action.payload
    },
    setIsEditOpen: (state, action) => {
      state.isEditOpen = action.payload
    },
    setHandbookName: (state, action) => {
      state.handbookName = action.payload
    },
    setProductName: (state, action) => {
      state.productName = action.payload
    },
    setHandbookCode: (state, action) => {
      state.handbookCode = action.payload
    },
    setHandboobCodeForEdit: (state, action) => {
      state.handbookCodeForEdit = action.payload
    },
    setDependCode: (state, action) => {
      state.dependCode = action.payload
    },
    setIsDependOpen: (state, action) => {
      state.isDependOpen = action.payload
    },
  },
})
//ACTIONS
export const {
  setHandbooks,
  setHanbookInfo,
  setDependInfo,
  setHanbookType,
  setIsEditOpen,
  setHandbookName,
  setDependList,
  setProductName,
  setDependType,
  setHandbookCode,
  setDependCode,
  setDependName,
  setIsDependOpen,
  setHandbook,
  setHandboobCodeForEdit,
} = handbookSlice.actions

// selectors
export const selectHandbookList = (state) => state.handbook.handbooks
export const selectDependList = (state) => state.handbook.dependList
export const selectHandbookInfo = (state) => state.handbook.handbookInfo
export const selectDependInfo = (state) => state.handbook.dependInfo
export const selectHandbookType = (state) => state.handbook.handbookType
export const selectDependType = (state) => state.handbook.dependType
export const selectIsEditOpen = (state) => state.handbook.isEditOpen
export const selectIsDependOpen = (state) => state.handbook.isDependOpen
export const selectHandbookName = (state) => state.handbook.handbookName
export const selectProductName = (state) => state.handbook.productName
export const selectDependName = (state) => state.handbook.dependName
export const selectHandbookCode = (state) => state.handbook.handbookCode
export const selectDependCode = (state) => state.handbook.dependCode
export const selectHandbook = (state) => state.handbook.handbook
export const selectHandbookCodeForEdit = (state) => state.handbook.handbookCodeForEdit

// thunk action creators
export const getHandbooks = (url) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(url)
      const appData = await response.data.data
      dispatch(setHandbooks(appData))
    } catch (err) {
      throw new Error(err)
    }
  }
}
export const getHandbookInfo = (url, obj) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(url, obj)
      const appData = await response.data.data
      dispatch(setHanbookInfo(appData))
    } catch (err) {
      throw new Error(err)
    }
  }
}
export const getDependInfo = (url, obj) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(url, obj)
      const appData = await response.data.data
      dispatch(setDependInfo(appData))
    } catch (err) {
      throw new Error(err)
    }
  }
}

export const getDependList = (url, type) => {
  const obj = {
    P_TYPE: type,
  }
  return async (dispatch) => {
    try {
      const response = await axios.post(url, obj)
      const appData = await response.data.data
      dispatch(setDependList(appData))
    } catch (err) {
      throw new Error(err)
    }
  }
}

export default handbookSlice.reducer
