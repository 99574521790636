import { useSelector } from 'react-redux'
import { selectPeriod, selectStatistic } from '../../../slices/statisctisSlice'
import { Checkbox, Col, Row } from 'antd'
import './styles.css'
import { useState } from 'react'

const StatisticsTable = () => {
  const period = useSelector(selectPeriod)
  const statistic = useSelector(selectStatistic)

  const [isFinished, setIsFinished] = useState(false)

  return (
    <>
      <div className="statistics-header-wrapper">
        <h2 className="statistics-header">Отчет за период {period}</h2>
        <Checkbox onChange={() => setIsFinished(!isFinished)}>
          Показать по завершенным
        </Checkbox>
      </div>
      {isFinished ? (
        <Row gutter={12} className="statistics-row" justify="center">
          <Col span={4} className="statistics-cell statistics-header">
            Продукт
          </Col>
          <Col span={6} className="statistics-cell statistics-header">
            Тема
          </Col>
          <Col span={3} className="statistics-cell statistics-header">
            Завершенные
          </Col>
          <Col span={3} className="statistics-cell statistics-header">
            Из них Дано разъяснение (решение посредством функций АИС ПРИС)
          </Col>
          <Col span={3} className="statistics-cell statistics-header">
            Из них Разрешена специалистом техподдержки
          </Col>
          <Col span={3} className="statistics-cell statistics-header">
            Из них Разрешена с привлечением программистов
          </Col>
        </Row>
      ) : (
        <Row gutter={12} className="statistics-row" justify="center">
          <Col span={4} className="statistics-cell statistics-header">
            Продукт
          </Col>
          <Col span={6} className="statistics-cell statistics-header">
            Тема
          </Col>
          <Col span={2} className="statistics-cell statistics-header">
            Новые
          </Col>
          <Col span={2} className="statistics-cell statistics-header">
            В работе
          </Col>
          <Col span={2} className="statistics-cell statistics-header">
            Ожидают завершения
          </Col>
          <Col span={2} className="statistics-cell statistics-header">
            Завершены
          </Col>
          <Col span={2} className="statistics-cell statistics-header">
            Всего заявок
          </Col>
        </Row>
      )}
      {statistic.map((el) => {
        return isFinished ? (
          <Row key={el.rn} gutter={12} className="statistics-row" justify="center">
            <Col span={4} className="statistics-cell">
              {el.product}
            </Col>
            <Col span={6} className="statistics-cell">
              {el.topic}
            </Col>
            <Col span={3} className="statistics-cell">
              {el.count_close}
            </Col>
            <Col span={3} className="statistics-cell">
              {el.count_close_explain}
            </Col>
            <Col span={3} className="statistics-cell">
              {el.count_close_script}
            </Col>
            <Col span={3} className="statistics-cell">
              {el.count_close_special}
            </Col>
          </Row>
        ) : (
          <Row key={el.rn} gutter={12} className="statistics-row" justify="center">
            <Col span={4} className="statistics-cell">
              {el.product}
            </Col>
            <Col span={6} className="statistics-cell">
              {el.topic}
            </Col>
            <Col span={2} className="statistics-cell">
              {el.count_new}
            </Col>
            <Col span={2} className="statistics-cell">
              {el.count_work}
            </Col>
            <Col span={2} className="statistics-cell">
              {el.count_wait_close}
            </Col>
            <Col span={2} className="statistics-cell">
              {el.count_close}
            </Col>
            <Col span={2} className="statistics-cell">
              {el.count_app}
            </Col>
          </Row>
        )
      })}
    </>
  )
}

export default StatisticsTable
