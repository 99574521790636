import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { getCookie } from '../constants'
import { error } from '../constants/helpers'
import Urls from '../constants/urls'
import { setLoadingFalse, setLoadingTrue } from './variableListSlice'
const initialState = {
  users: [],
  user: {},
  userName: {},
  roles: [],
  searchUserParams: {},
  isSearch: false,
}

const cookie = getCookie()

export const userListSlice = createSlice({
  name: 'userList',
  initialState,
  reducers: {
    setUserList: (state, action) => {
      state.users = action.payload
    },
    setUser: (state, action) => {
      state.user = state.users.find((user) => user.user_id === action.payload)
    },
    setUserName: (state, action) => {
      state.userName = action.payload[0]
    },
    setRoles: (state, action) => {
      state.roles = action.payload
    },
    setSearchUserParams: (state, action) => {
      state.searchUserParams = action.payload
    },
    setIsSearch: (state, action) => {
      state.isSearch = action.payload
    },
  },
})
//ACTIONS
export const {
  setUserList,
  setUser,
  setUserName,
  setRoles,
  setIsSearch,
  setSearchUserParams,
} = userListSlice.actions

// selectors
export const selectUserList = (state) => state.userList.users
export const selectUser = (state) => state.userList.user
export const selectUserName = (state) => state.userList.userName
export const selectRoles = (state) => state.userList.roles
export const selectIsSearch = (state) => state.userList.isSearch
export const selectSearchUserParams = (state) => state.userList.searchUserParams

// thunk action creators
export const getUserList = (url) => {
  return async (dispatch) => {
    try {
      dispatch(setLoadingTrue())
      const response = await axios.get(url)
      const appData = await response.data.data
      dispatch(setUserList(appData))
      await dispatch(setLoadingFalse())
    } catch (err) {
      throw new Error(err)
    }
  }
}
export const getClientList = (url) => {
  const userId = cookie['p_user_id']
  const obj = { p_user_id: userId }
  return async (dispatch) => {
    try {
      dispatch(setLoadingTrue())
      const response = await axios.post(url, obj)
      const appData = await response.data.data
      await dispatch(setLoadingFalse())
      dispatch(setUserList(appData))
    } catch (err) {
      throw new Error(err)
    }
  }
}
export const getUserName = (url, userId) => {
  const obj = {
    P_USER_ID: userId,
  }
  return async (dispatch) => {
    try {
      const response = await axios.post(url, obj)
      const appData = await response.data.data
      dispatch(setUserName(appData))
    } catch (err) {
      throw new Error(err)
    }
  }
}

export const searchUsers = () => {
  return async (dispatch, getState) => {
    const urls = Urls()
    dispatch(setLoadingTrue())
    const obj = getState().userList.searchUserParams
    try {
      const response = await axios.post(urls.SearchUser, obj)
      const data = response.data.data
      dispatch(setLoadingFalse())
      if (data.length < 1) {
        error(response.data.message)
      } else {
        dispatch(setUserList(data))
        dispatch(setIsSearch(true))
      }
    } catch (err) {
      dispatch(setLoadingFalse())
      throw new Error(err)
    }
  }
}

export default userListSlice.reducer
