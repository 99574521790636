import './styles.css'
import { Tooltip } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { notifications, Role, roles } from '../../../constants'
import { BugOutlined } from '@ant-design/icons'
import { getAppInfo, selectApplications } from '../../../slices/applicationSlice'
import { setIsAppOpen, setAppId, selectAppId } from '../../../slices/applicationSlice'
import Urls from '../../../constants/urls'
import { useEffect, useRef } from 'react'

const Application = ({
  date,
  header,
  topic,
  client,
  id,
  product,
  personal,
  notification,
  isTest,
  isAppOpen,
}) => {
  const dispatch = useDispatch()
  const urls = Urls()
  const appId = useSelector(selectAppId)
  const applications = useSelector(selectApplications)
  const role = Role()
  const isClientRole = role === roles.client || role === roles.clientAdmin
  const isTester = role === roles.tester
  const isSelected = id === appId
  const isClientAnswered = notification === notifications.clientAnswered
  const isPersonalAnswered = notification === notifications.supportAnswered
  const isToTest = !isClientRole && isTest !== 0
  const scrollRef = useRef(null)

  const onCard = (event, id) => {
    if (id !== appId) {
      dispatch(setAppId(id))
      dispatch(getAppInfo(urls.GetAppInf))
      dispatch(setIsAppOpen(true))
    }
  }

  useEffect(() => {
    if (isSelected) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [applications, isAppOpen, isSelected])

  return (
    <div
      className={`application-card ${isSelected && 'application-card-selected'} ${
        isAppOpen ? 'application-card-big' : 'application-card-small'
      }`}
      onClick={(event) => onCard(event, id)}
      ref={scrollRef}
    >
      <div className={isAppOpen ? 'application-point-open' : 'application-point'}>
        <span className={isAppOpen ? 'date-update' : ''}>{date}</span>
        {isToTest && (
          <span
            className={
              isTest === 2 ? 'application-on-test waiting-answer' : 'application-on-test'
            }
          >
            {
              <Tooltip title="Заявка находится на тестировании">
                <BugOutlined />
              </Tooltip>
            }
          </span>
        )}
        {!isTester &&
          ((isClientAnswered && (
            <span className="application-on-test">
              {isClientRole ? (
                <p>Вы ответили на заявку</p>
              ) : (
                <p className="waiting-answer">Заявка ждет ответа</p>
              )}
            </span>
          )) ||
            (isPersonalAnswered && (
              <span className="application-on-test">
                {isClientRole ? (
                  <p className="waiting-answer">Заявка ждет ответа</p>
                ) : (
                  <p>Вы ответили на заявку</p>
                )}
              </span>
            )))}
      </div>
      <h2>Заявка номер: {header}</h2>
      <p className="card-text"> Продукт: {product}</p>
      <p className="card-text">Тема: {topic}</p>
      {client && <p>От кого: {client}</p>}
      {personal && <p style={{ fontStyle: 'italic' }}> Специалист: {personal}</p>}
    </div>
  )
}
export default Application
