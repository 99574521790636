import { Menu, Button, Form, Select, Tooltip } from 'antd'
import {
  SortAscendingOutlined,
  SortDescendingOutlined,
  FilterFilled,
  FilterTwoTone,
} from '@ant-design/icons'
import './styles.css'
import {
  getApplications,
  selectStatusesWithNumber,
  setIsCreateApp,
  setIsAppOpen,
  setAppId,
  selectApplications,
  setApplications,
  getStatuses,
} from '../../../slices/applicationSlice'
import { useSelector, useDispatch } from 'react-redux'
import { Role, filterOption, roles } from '../../../constants'
import { useMemo, useState } from 'react'
import {
  selectProducts,
  selectTopics,
  getTopics,
  setTopics,
} from '../../../slices/variableListSlice'
import Urls from '../../../constants/urls'
import { useUserInfo } from '../../../hooks/useUserInfo'
const { Option } = Select

const ApplicationMenu = () => {
  const [form] = Form.useForm()
  const urls = Urls()
  const applications = useSelector(selectApplications)
  const dispatch = useDispatch()
  const role = Role()
  const { userClientId, userPersId, userInfo } = useUserInfo()
  const isClientRole = role === roles.client || role === roles.clientAdmin
  const isTestRole = role === roles.tester

  const [topic, setTopic] = useState(null)
  const [productCode, setProductCode] = useState(null)
  const [status, setStatus] = useState(2)
  const [isSearch, setIsSearch] = useState(false)
  const [isSortedNew, setIsSortedNew] = useState(false)
  const [toShowFilter, setToShowFilter] = useState(false)
  const products = useSelector(selectProducts)
  const topics = useSelector(selectTopics)
  const statuses = useSelector(selectStatusesWithNumber)

  const filteredStatuses = useMemo(() => {
    return isTestRole ? statuses.filter((el) => el.code !== 1) : statuses
  }, [isTestRole, statuses])

  const items = useMemo(() => {
    return filteredStatuses.map((el) => {
      return {
        label: el.lex1,
        key: el.code,
      }
    })
  }, [filteredStatuses])

  //Слушатели событий
  const onStatus = ({ key }, isCancelSearch = false) => {
    setIsSortedNew(false)
    dispatch(setIsCreateApp(false))
    dispatch(setIsAppOpen(false))
    dispatch(setAppId(''))
    if (isClientRole) {
      const obj = {
        P_CLIENT_ID: userClientId,
        P_PRODUCT_ID: isCancelSearch ? null : productCode,
        P_TOPIC_ID: isCancelSearch ? null : topic,
      }
      dispatch(getStatuses(urls.GetStatList, obj))
      switch (Number(key)) {
        case 1:
          dispatch(getApplications(urls.NewByClient, obj))
          setStatus(1)
          break
        case 2:
          dispatch(getApplications(urls.InWorkByClient, obj))
          setStatus(2)
          break
        case 4:
          dispatch(getApplications(urls.DoneByClient, obj))
          setStatus(4)
          break
        default:
          dispatch(getApplications(urls.InWorkByClient, obj))
          setStatus(2)
          break
      }
    } else {
      const obj = {
        P_PERS_ID: userPersId,
        P_PRODUCT_ID: isCancelSearch ? null : productCode,
        P_TOPIC_ID: isCancelSearch ? null : topic,
      }
      dispatch(getStatuses(urls.GetStatList, obj))
      switch (Number(key)) {
        case 1:
          dispatch(getApplications(urls.GetNewAppList, obj))
          setStatus(1)
          break
        case 2:
          dispatch(getApplications(urls.GetInWorkList, obj))
          setStatus(2)
          break
        case 4:
          dispatch(getApplications(urls.GetDoneAppList, obj))
          setStatus(4)
          break
        default:
          dispatch(getApplications(urls.GetInWorkList, obj))
          setStatus(2)
      }
    }
  }

  const onCancelSearch = () => {
    setIsSearch(false)
    setProductCode(null)
    setTopic(null)
    dispatch(setTopics([]))
    form.resetFields(['topic', 'product'])
    onStatus({ key: status }, true)
  }

  const onCreateApp = () => {
    dispatch(setAppId(''))
    dispatch(setIsCreateApp(true))
    dispatch(setIsAppOpen(false))
  }

  const onChangeProduct = (productCode) => {
    setProductCode(productCode)
    dispatch(getTopics(urls.GetTopics, productCode))
  }

  const onClearProduct = () => {
    setProductCode(null)
    setTopic(null)
    dispatch(setTopics([]))
    form.resetFields(['topic'])
  }

  const onSearchBtn = () => {
    dispatch(setIsAppOpen(false))
    dispatch(setAppId(''))
    setIsSearch(true)
    const applicationObj = {
      P_CLIENT_ID: isClientRole ? userClientId : null,
      P_PERS_ID: isClientRole ? null : userPersId,
      P_PRODUCT: productCode,
      P_TOPIC: topic,
      P_APP_STATUS: status,
      P_FIO_USER: isClientRole ? '' : status === 1 ? '' : userInfo,
      P_FIO_CLIENT: isClientRole ? userInfo : '',
    }
    dispatch(getApplications(urls.ExtAppSearch, applicationObj))
    const statusesObj = {
      P_PERS_ID: isClientRole ? null : userPersId,
      P_CLIENT_ID: isClientRole ? userClientId : null,
      P_PRODUCT_ID: productCode,
      P_TOPIC_ID: topic,
    }
    dispatch(getStatuses(urls.GetStatList, statusesObj))
  }

  const onSort = () => {
    let sortedApps
    if (isSortedNew) {
      sortedApps = [...applications].sort((a, b) => a['app_id'] - b['app_id'])
    } else {
      sortedApps = [...applications].sort((a, b) => b['app_id'] - a['app_id'])
    }
    dispatch(setApplications(sortedApps))
    setIsSortedNew(!isSortedNew)
  }

  return (
    <div className="application-menu-wrapper">
      <Menu
        className="application-menu"
        items={items}
        mode={'inline'}
        defaultSelectedKeys={'2'}
        onSelect={(item) => onStatus(item)}
      />
      {items.length ? (
        <div className="menu-filter-btns">
          <Tooltip title={toShowFilter ? 'Скрыть фильтр' : 'Показать фильтр'}>
            <Button
              icon={toShowFilter ? <FilterTwoTone /> : <FilterFilled />}
              style={{ alignSelf: 'flex-end' }}
              onClick={() => setToShowFilter(!toShowFilter)}
            />
          </Tooltip>
          <Tooltip title={isSortedNew ? 'Сначала старые' : 'Сначала Новые'}>
            <Button
              icon={isSortedNew ? <SortAscendingOutlined /> : <SortDescendingOutlined />}
              style={{ alignSelf: 'flex-end' }}
              onClick={onSort}
            />
          </Tooltip>
        </div>
      ) : null}
      {toShowFilter && (
        <Form
          className="search-app-form"
          name="search-app"
          layout={'vertical'}
          style={{ background: '#fff' }}
          form={form}
        >
          <h2>Фильтр</h2>
          <Form.Item className="form-item" label="Продукт" name="product">
            <Select
              className="form-input app-info"
              onSelect={(_, option) => onChangeProduct(option.value)}
              onClear={onClearProduct}
              allowClear={true}
              showSearch={true}
              filterOption={filterOption}
            >
              {products.map((el) => {
                return (
                  <Option key={el.code} value={el.code}>
                    {el.lex1}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
          <Form.Item className="form-item" label="Тема" name="topic">
            <Select
              allowClear={true}
              className="form-input"
              onSelect={(_, option) => setTopic(option.value)}
              onClear={() => setTopic(null)}
              dropdownMatchSelectWidth={300}
              showSearch={true}
              filterOption={filterOption}
              notFoundContent="Данные не найдены"
              dropdownStyle={{ maxWidth: '100%' }}
            >
              {topics.map((el) => {
                return (
                  <Option key={el.code} value={el.code}>
                    {el.lex1}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
          <Form.Item style={{ width: '100%' }}>
            <Button
              className="btn-submit"
              disabled={!productCode}
              onClick={onSearchBtn}
              type="primary"
            >
              Применить
            </Button>
            {isSearch && (
              <Button size="small" onClick={onCancelSearch}>
                Сбросить
              </Button>
            )}
          </Form.Item>
        </Form>
      )}
      {isClientRole && (
        <Button
          className="btn-submit"
          style={{
            background: 'rgba(84, 152, 195, 0.7)',
            color: '#fff',
            margin: '1rem 0.5rem',
            padding: '0 2rem',
          }}
          onClick={onCreateApp}
        >
          Создать заявку
        </Button>
      )}
    </div>
  )
}

export default ApplicationMenu
