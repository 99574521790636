import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectHandbookInfo,
  selectHandbookName,
  getDependList,
  selectHandbookType,
  selectDependInfo,
  setHandbookCode,
  selectDependType,
  setProductName,
  selectDependName,
  selectProductName,
  setIsDependOpen,
  selectHandbookCode,
  getDependInfo,
  getHandbookInfo,
  setDependCode,
  setHandbook,
  selectHandbook,
  setHandboobCodeForEdit,
  setDependList,
} from '../../../slices/handbookSlice'
import { Col, Row, Button, Space, Tooltip } from 'antd'
import {
  EditOutlined,
  CloseOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from '@ant-design/icons'
import EditHandbookModal from '../EditHandbookModal'
import './styles.css'
import useHttp from '../../../hooks/useHttp'
import { confirm, error, success } from '../../../constants/helpers'
import Urls from '../../../constants/urls'
import { useUserInfo } from '../../../hooks/useUserInfo'

const HandbookEdit = ({ isDepend = false }) => {
  const dispatch = useDispatch()
  const urls = Urls()
  const { userId } = useUserInfo()
  const { postRequest } = useHttp()
  const handbookElements = useSelector(isDepend ? selectDependInfo : selectHandbookInfo)
  const handbookName = useSelector(selectHandbookName)
  const dependName = useSelector(selectDependName)
  const productName = useSelector(selectProductName)
  const handbookType = useSelector(selectHandbookType)
  const dependType = useSelector(selectDependType)
  const handbookCode = useSelector(selectHandbookCode)
  const handbook = useSelector(selectHandbook)

  const [open, setOpen] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const span = isDepend && dependType === 202 ? 4 : 5
  const isProductHandbook = !isDepend && handbookType === 201

  //Колбэки
  const deleteItemCallback = (resp) => {
    if (resp.data) {
      if (isDepend) {
        const obj = {
          P_TYPE: handbookType,
          P_CODE: handbookCode,
          P_TYPE_DEPEND: dependType,
        }
        dispatch(getDependInfo(urls.GetDependUni, obj))
      } else {
        const obj = {
          P_KL_TYPE: handbookType,
        }
        dispatch(getHandbookInfo(urls.GetKlUni, obj))
      }
      success(resp.data[0].response, 'Удалено!')
    } else error(resp.message)
  }

  //Слушатели событий
  const onAddInHandbook = () => {
    setOpen(true)
  }
  const onEditHandbookItem = (code) => {
    setIsEdit(true)
    if (isDepend) {
      dispatch(setHandbook({ type: 'depend', code: code }))
      dispatch(setDependCode(code))
    } else {
      dispatch(setHandbook({ type: 'handbook', code: code }))
      dispatch(setHandboobCodeForEdit(code))
    }
    setOpen(true)
  }

  const onOpenDepend = (name, code) => {
    if (code !== handbookCode) {
      isDepend
        ? dispatch(setHandbook({ type: 'depend', code: code }))
        : dispatch(setHandbook({ type: 'handbook', code: code }))
      dispatch(setIsDependOpen(false))
      dispatch(getDependList(urls.GetTypeDepend, handbookType))
      dispatch(setProductName(name))
      dispatch(setHandbookCode(code))
    } else {
      dispatch(setDependList([]))
      dispatch(setHandbookCode(0))
    }
  }

  const showHandbookDelete = (code) => {
    const title = 'Вы действительно хотите удалить справочник?'
    const url = urls.DelKlUni
    const obj = {
      P_USER_ID: userId,
      P_TYPE: isDepend ? dependType : handbookType,
      P_CODE: code,
    }
    confirm(title, obj, url, deleteItemCallback, postRequest)
  }

  return (
    <>
      <h2 className="handbook-header-name">
        {isDepend ? `${dependName} по ${productName}` : handbookName}:
      </h2>
      <Row className="handbook-row">
        <Col span={span} className="handbook-cell">
          Значение
        </Col>
        {span === 4 && (
          <Col span={span} className="handbook-cell">
            Обязательный параметр
          </Col>
        )}
        <Col span={span} className="handbook-cell">
          Актуальность
        </Col>
        <Col span={span} className="handbook-cell">
          Добавлено
        </Col>
        <Col span={span} className="handbook-cell">
          Удалено
        </Col>
        <Col span={4} className="handbook-cell">
          Редактировать/Удалить
        </Col>
      </Row>
      {handbookElements.map((el) => {
        return (
          <Row
            key={el.code}
            className={el.active ? 'handbook-row' : 'handbook-row handbook-deleted'}
          >
            <Col span={span} className="handbook-cell">
              {el.lex1}
            </Col>
            {span === 4 && (
              <Col span={span} className="handbook-cell">
                {el.lex3}
              </Col>
            )}
            <Col span={span} className="handbook-cell">
              {el.active}
            </Col>
            <Col span={span} className="handbook-cell">
              {el.date_begin}
            </Col>
            <Col span={span} className="handbook-cell">
              {el.date_end}
            </Col>
            <Col span={4} className="handbook-cell">
              <Space style={{ width: '100%', justifyContent: 'center' }}>
                <Tooltip title="Редактировать элемент справочника">
                  <Button
                    shape="circle"
                    icon={<EditOutlined />}
                    className={el.active ? 'edit-hb-btn' : ''}
                    onClick={() => onEditHandbookItem(el.code)}
                    disabled={!el.active}
                  />
                </Tooltip>
                {el.depend === 1 && (
                  <Tooltip title="Просмотр зависимых справочников">
                    <Button
                      shape="circle"
                      icon={
                        handbookCode === el.code ? (
                          <EyeInvisibleOutlined />
                        ) : (
                          <EyeOutlined />
                        )
                      }
                      className={el.active ? 'edit-hb-btn' : ''}
                      onClick={() => onOpenDepend(el.lex1, el.code)}
                      disabled={!el.active}
                    />
                  </Tooltip>
                )}
                <Tooltip title="Удалить справочник">
                  <Button
                    shape="circle"
                    className={el.active ? 'edit-hb-btn' : ''}
                    icon={<CloseOutlined />}
                    onClick={() => showHandbookDelete(el.code)}
                    disabled={!el.active}
                  />
                </Tooltip>
              </Space>
            </Col>
          </Row>
        )
      })}
      {!isProductHandbook && handbook.active !== 0 && (
        <Col className="sticy-button" style={{ textAlign: 'end' }}>
          <Button className="add-btn" onClick={onAddInHandbook}>
            Добавить
          </Button>
        </Col>
      )}
      {open && (
        <EditHandbookModal
          open={open}
          setOpen={setOpen}
          isDepend={isDepend}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      )}
    </>
  )
}

export default HandbookEdit
