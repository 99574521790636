import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { Token } from '../constants'
import Urls from '../constants/urls'
import { setLoadingFalse, setLoadingTrue } from './variableListSlice'

export const token = Token()

const initialState = {
  applications: [],
  statusesWithNumber: [],
  statusesOnly: [],
  isCreateApp: false,
  isAppOpen: false,
  appInfo: {},
  appComments: [],
  appId: 0,
  getAppUrl: '',
  getAppObj: {},
  informationTemplates: [],
}

export const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setApplications: (state, action) => {
      state.applications = action.payload
    },
    setStatuses: (state, action) => {
      state.statusesWithNumber = action.payload
    },
    setStatusesOnly: (state, action) => {
      state.statusesOnly = action.payload
    },
    setIsCreateApp: (state, action) => {
      state.isCreateApp = action.payload
    },
    setIsAppOpen: (state, action) => {
      state.isAppOpen = action.payload
    },
    setAppInfo: (state, action) => {
      state.appInfo = action.payload
    },
    setAppComments: (state, action) => {
      state.appComments = action.payload
    },
    setAppId: (state, action) => {
      state.appId = action.payload
    },
    setGetAppUrl: (state, action) => {
      state.getAppUrl = action.payload
    },
    setGetAppObj: (state, action) => {
      state.getAppObj = action.payload
    },
    setInformationTemplates: (state, action) => {
      state.informationTemplates = action.payload
    },
  },
})
export const {
  setApplications,
  setStatuses,
  setIsCreateApp,
  setIsAppOpen,
  setAppInfo,
  setAppId,
  setAppComments,
  setStatusesOnly,
  setGetAppUrl,
  setGetAppObj,
  setInformationTemplates,
} = applicationSlice.actions

export const selectApplications = (state) => state.application.applications
export const selectStatusesWithNumber = (state) => state.application.statusesWithNumber
export const selectStatusesOnly = (state) => state.application.statusesOnly
export const selectIsCreateApp = (state) => state.application.isCreateApp
export const selectIsAppOpen = (state) => state.application.isAppOpen
export const selectAppInfo = (state) => state.application.appInfo
export const selectAppComments = (state) => state.application.appComments
export const selectAppId = (state) => state.application.appId
export const selectGetAppUrl = (state) => state.application.getAppUrl
export const selectGetAppObj = (state) => state.application.getAppObj
export const selectInformationTemplates = (state) => state.application.informationTemplates

// thunk action creators
export const getAppInfo = (url) => {
  return async (dispatch, getState) => {
    const obj = {
      P_APP_ID: getState().application.appId,
    }
    try {
      const response = await axios.post(url, obj)
      const appData = await response.data.data
      dispatch(setAppInfo(appData[0]))
    } catch (err) {
      throw new Error(err)
    }
  }
}
export const getFileAppInfo = (appId, fileName) => {
  return async () => {
    try {
      const response = await axios.get(`${Urls().GetAppInf}?p_app_id=${appId}`, {
        responseType: 'blob',
      })
      handleResponse(response.data, fileName)
    } catch (err) {
      throw new Error(err)
    }
  }
}

export const getFileComment = (fileId, fileName) => {
  return async () => {
    try {
      const response = await axios.get(
        `${Urls().GetFile}?p_file_id=${fileId}&p_FileName=${fileName}`,
        { responseType: 'blob' }
      )
      handleResponse(response.data, fileName)
    } catch (err) {
      throw new Error(err)
    }
  }
}

const handleResponse = (response, fileName) => {
  const link = document.createElement('a')
  const url = URL.createObjectURL(response)
  link.href = url
  link.download = fileName
  link.click()
}

export const getApplications = (url, obj) => {
  return async (dispatch) => {
    dispatch(setLoadingTrue())
    try {
      const response = await axios.post(url, obj)
      const appData = await response.data.data
      dispatch(setApplications(appData))
      dispatch(setLoadingFalse())
      dispatch(setGetAppUrl(url))
      dispatch(setGetAppObj(obj))
    } catch (err) {
      dispatch(setLoadingFalse())
      throw new Error(err)
    }
  }
}

export const getAppComments = (url, obj) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(url, obj)
      const appData = await response.data.data
      dispatch(setAppComments(appData))
    } catch (err) {
      throw new Error(err)
    }
  }
}
export const getInformationTemplates = (url) => {
  const obj = {
    // p_app_id: 801,
    p_app_id: 2957,
  }
  return async (dispatch) => {
    try {
      const response = await axios.post(url, obj)
      const appData = await response.data.data
      dispatch(setInformationTemplates(appData))
    } catch (err) {
      throw new Error(err)
    }
  }
}

export const getStatuses =
  (url, data = {}) =>
  async (dispatch) => {
    try {
      const response = await axios.post(url, data)
      dispatch(setStatuses(response.data.data))
    } catch (err) {
      throw new Error(err)
    }
  }
export const getStatusesOnly =
  (data = {}) =>
  async (dispatch) => {
    const url = Urls().GetStatList
    try {
      const response = await axios.post(url, data)
      dispatch(setStatusesOnly(response.data.data))
    } catch (err) {
      throw new Error(err)
    }
  }

export default applicationSlice.reducer
