import React, { memo, useEffect, useMemo, useState } from 'react'
import { Form, Select, Input, Space, Radio, Button } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import {
  getClientList,
  getUserList,
  searchUsers,
  selectIsSearch,
  setIsSearch,
  setSearchUserParams,
} from '../../../slices/userListSlice'
import useHttp from '../../../hooks/useHttp'
import { Role, filterOption, roles } from '../../../constants'
import {
  getDepartments,
  selectDepartments,
  selectPosts,
  selectProducts,
  selectRegions,
  setDepartments,
} from '../../../slices/variableListSlice'
import './styles.css'
import {
  addMultipleSelectString,
  error,
  filterHandbookValuesArray,
  filterHandbookValuesString,
  removeMultipleSelectString,
  rules,
  success,
} from '../../../constants/helpers'
import Urls from '../../../constants/urls'
import { useUserInfo } from '../../../hooks/useUserInfo'
const { Option } = Select

const CreateUser = memo(() => {
  const { postRequest } = useHttp()
  const dispatch = useDispatch()

  const urls = Urls()
  const [form] = Form.useForm()
  const { clientMainProduct, clientProductsList, userRegions, userId } = useUserInfo()
  const role = Role()
  const isAdmin = useMemo(() => role === roles.admin, [role])
  const isClientAdmin = useMemo(() => role === roles.clientAdmin, [role])

  const products = useSelector(selectProducts)
  const departments = useSelector(selectDepartments)
  const posts = useSelector(selectPosts)
  const regions = useSelector(selectRegions)
  const search = useSelector(selectIsSearch)

  const initialValue = useMemo(() => {
    return {
      P_ROLE_ID: isClientAdmin ? 2 : null,
      P_LOGIN: null,
      P_SURNAME: null,
      P_FIRSTNAME: null,
      P_FATHERNAME: null,
      P_PRODUCT: isClientAdmin ? clientMainProduct : null,
      P_DEPART: null,
      P_POST: null,
      P_PASS: 123,
      P_PARENT_USER_ID: userId,
      P_REGIONS: [],
    }
  }, [clientMainProduct, isClientAdmin, userId])

  const filteredProductsList = useMemo(() => {
    return filterHandbookValuesArray(products, clientProductsList)
  }, [clientProductsList, products])

  const [formValue, setFormValue] = useState(initialValue)
  const [isCreate, setIsCreate] = useState(false)
  const [addProducts, setAddProducts] = useState(filteredProductsList)
  const [addProductString, setAddProductString] = useState('')

  const filteredRegions = useMemo(() => {
    return regions.length ? filterHandbookValuesString(regions, userRegions) : []
  }, [regions, userRegions])

  const isClientRoleId = useMemo(
    () =>
      formValue.P_ROLE_ID === roles.client || formValue.P_ROLE_ID === roles.clientAdmin,
    [formValue.P_ROLE_ID]
  )

  //Кол-бэки
  const createUserCallback = (response) => {
    if (response.data) {
      form.resetFields()
      setFormValue(initialValue)
      success(response.data[0].response)
      dispatch(
        isClientAdmin ? getClientList(urls.GetClientList) : getUserList(urls.GetUsersList)
      )
    } else error(response.message)
  }

  //Слушатели событий
  const onAddUser = () => {
    const obj = JSON.parse(JSON.stringify(formValue))
    obj.P_REGIONS = obj.P_REGIONS.join()
    obj.P_PRODUCT = `${obj.P_PRODUCT}${addProductString}`
    postRequest({ url: urls.ADDUSER, data: obj, callback: createUserCallback })
  }

  const onSearch = () => {
    const obj = {
      P_SURNAME: formValue.P_SURNAME,
      P_FIRSTNAME: formValue.P_FIRSTNAME,
      P_FATHERNAME: formValue.P_FATHERNAME,
      P_ROLE: formValue.P_ROLE_ID,
      P_LOGIN: formValue.P_LOGIN,
      P_PRODUCT: formValue.P_PRODUCT,
      P_USER_ID: userId,
    }
    dispatch(setSearchUserParams(obj))
    dispatch(searchUsers(urls.SearchUser))
  }

  const OnChangeRole = (roleId) => {
    setFormValue({
      ...formValue,
      P_ROLE_ID: roleId,
      P_DEPART: initialValue.P_DEPART,
      P_POST: initialValue.P_POST,
    })
    form.resetFields(['P_DEPART', 'P_POST'])
  }

  const onCloseSearch = () => {
    dispatch(setIsSearch(false))
    dispatch(setSearchUserParams({}))
    dispatch(
      isClientAdmin ? getClientList(urls.GetClientList) : getUserList(urls.GetUsersList)
    )
  }

  const onChangeProduct = (productCode) => {
    setFormValue({
      ...formValue,
      P_PRODUCT: productCode,
      P_DEPART: initialValue.P_DEPART,
      P_POST: initialValue.P_POST,
    })
    dispatch(getDepartments(urls.GetDepart, productCode))
    form.resetFields(['P_DEPART', 'P_POST', 'P_ADD_PRODUCT'])
    setAddProductString('')
  }

  const onAddProduct = (productCode) => {
    const productCodes = addMultipleSelectString(addProductString, productCode)
    setAddProductString(productCodes)
  }
  const onRemoveProduct = (productCode) => {
    const productCodes = removeMultipleSelectString(addProductString, productCode)
    setAddProductString(productCodes)
  }

  const onClearProduct = () => {
    setFormValue({
      ...formValue,
      P_PRODUCT: initialValue.P_PRODUCT,
      P_DEPART: initialValue.P_DEPART,
      P_POST: initialValue.P_POST,
    })
    dispatch(setDepartments([]))
    setAddProductString('')
    form.resetFields(['P_PRODUCT', 'P_DEPART', 'P_POST', 'P_ADD_PRODUCT'])
  }

  const onSelectRegions = (region) => {
    const selectedRegions = formValue.P_REGIONS
    selectedRegions.push(region)
  }

  const onDeselectRegions = (region) => {
    const selectedRegions = formValue.P_REGIONS
    const filteredRegions = selectedRegions.filter((value) => value !== region)
    setFormValue({ ...formValue, P_REGIONS: filteredRegions })
  }

  useEffect(() => {
    if (isClientAdmin) {
      dispatch(getDepartments(urls.GetDepart, formValue.P_PRODUCT))
    }
    const filteredAddProducts = filteredProductsList.filter(
      (product) => product.code !== formValue.P_PRODUCT
    )
    setAddProducts(filteredAddProducts)
  }, [formValue.P_PRODUCT])

  useEffect(() => {
    const fields = form.getFieldsError().map((field) => {
      return { ...field, errors: [] }
    })
    form.setFields(fields)
  }, [isCreate, form])

  return (
    <Form
      className="create-user"
      name="create-user"
      form={form}
      onFinish={onAddUser}
      initialValues={initialValue}
      labelCol={{
        span: 10,
      }}
      wrapperCol={{
        span: 18,
      }}
      layout={'vertical'}
      style={{ width: '35%', background: '#fff', padding: '1rem' }}
    >
      <Radio.Group
        className="user-form"
        defaultValue={isCreate}
        buttonStyle="solid"
        onChange={(e) => setIsCreate(e.target.value)}
      >
        <Radio.Button value={true}>Создание пользователей</Radio.Button>
        <Radio.Button value={false}>Поиск пользователей</Radio.Button>
      </Radio.Group>
      <Form.Item
        label="Фамилия"
        name="P_SURNAME"
        className="form-item user-form"
        rules={isCreate ? rules : []}
      >
        <Input
          className="form-input"
          onChange={(e) => setFormValue({ ...formValue, P_SURNAME: e.target.value })}
        />
      </Form.Item>
      <Form.Item
        label="Имя"
        name="P_FIRSTNAME"
        className="form-item user-form"
        rules={isCreate ? rules : []}
      >
        <Input
          className="form-input"
          onChange={(e) => setFormValue({ ...formValue, P_FIRSTNAME: e.target.value })}
        />
      </Form.Item>
      <Form.Item
        label="Отчество"
        name="P_FATHERNAME"
        className="form-item user-form"
        rules={isCreate ? rules : []}
      >
        <Input
          className="form-input"
          onChange={(e) => setFormValue({ ...formValue, P_FATHERNAME: e.target.value })}
        />
      </Form.Item>
      <Form.Item
        label="Роль"
        name="P_ROLE_ID"
        className="form-item user-form"
        validateTrigger="onSelect"
        rules={isCreate ? rules : []}
      >
        <Select
          className={`form-input ${isClientAdmin && 'disabled-select'}`}
          onSelect={(_, option) => OnChangeRole(option.value)}
          allowClear={true}
          onClear={() => OnChangeRole(null)}
          disabled={isClientAdmin}
          showArrow={isAdmin}
        >
          <Option value={2}>Клиент</Option>
          <Option value={3}>Специалист тех.поддержки</Option>
          <Option value={4}>Тестировщик</Option>
          <Option value={5}>Уполномоч. лицо от заказчика</Option>
          <Option value={6}>Уполномоч. лицо от исполнителя</Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="Основной продукт"
        name="P_PRODUCT"
        className="form-item user-form"
        validateTrigger="onSelect"
        rules={isCreate ? rules : []}
      >
        <Select
          className={'form-input app-info'}
          onSelect={(_, option) => onChangeProduct(option.value)}
          onClear={onClearProduct}
          allowClear={true}
          filterOption={filterOption}
        >
          {filteredProductsList.map((el) => {
            return (
              <Option key={el.code} value={el.code}>
                {el.lex1}
              </Option>
            )
          })}
        </Select>
      </Form.Item>
      {isCreate && (
        <>
          <Form.Item
            label="Дополнительные продукты"
            className="form-item user-form"
            validateTrigger="onSelect"
            name="P_ADD_PRODUCT"
          >
            <Select
              className={'form-input app-info'}
              mode={'multiple'}
              onSelect={onAddProduct}
              showArrow={true}
              filterOption={filterOption}
              onDeselect={onRemoveProduct}
              disabled={!formValue.P_PRODUCT}
            >
              {addProducts.map((el) => {
                return (
                  <Option key={el.code} value={el.code}>
                    {el.lex1}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>

          <Form.Item
            label="Регион"
            name="P_REGIONS"
            className="form-item user-form"
            validateTrigger="onSelect"
            rules={isCreate ? rules : []}
          >
            <Select
              className={'form-input app-info'}
              mode="multiple"
              onSelect={onSelectRegions}
              onDeselect={onDeselectRegions}
              name="region-select"
            >
              {filteredRegions.map((el) => {
                return (
                  <Option key={el.code} value={el.code}>
                    {el.lex1}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </>
      )}
      {isClientRoleId && isCreate && (
        <>
          <Form.Item
            className="form-item user-form"
            name="P_DEPART"
            label="Подразделение/Организация"
            validateTrigger="onSelect"
            rules={isCreate ? rules : []}
          >
            <Select
              className="form-input"
              name="department-select"
              onSelect={(_, option) =>
                setFormValue({ ...formValue, P_DEPART: option.value })
              }
              showSearch={true}
              filterOption={filterOption}
              notFoundContent="Данные не найдены"
            >
              {departments.map((el) => {
                return (
                  <Option key={el.code} value={el.code}>
                    {el.lex1}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
          <Form.Item
            className="form-item user-form"
            name="P_POST"
            label="Должность"
            validateTrigger="onSelect"
            rules={isCreate ? rules : []}
          >
            <Select
              className="form-input"
              onSelect={(_, option) =>
                setFormValue({ ...formValue, P_POST: option.value })
              }
              showSearch={true}
              filterOption={filterOption}
            >
              {posts.map((el) => {
                return (
                  <Option key={el.code} value={el.code}>
                    {el.lex1}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </>
      )}
      <Form.Item
        label="Логин"
        name="P_LOGIN"
        className="form-item user-form"
        rules={isCreate ? rules : []}
      >
        <Input
          className="form-input"
          onChange={(e) => setFormValue({ ...formValue, P_LOGIN: e.target.value })}
        />
      </Form.Item>
      <Form.Item>
        <Space>
          {isCreate ? (
            <button className="btn-submit">Сохранить</button>
          ) : (
            <button className="btn-submit" type="button" onClick={onSearch}>
              Поиск
            </button>
          )}
          {search && (
            <Button style={{ margin: '0.5rem' }} onClick={onCloseSearch}>
              Сбросить поиск
            </Button>
          )}
        </Space>
      </Form.Item>
    </Form>
  )
})

export default CreateUser
