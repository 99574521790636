import { Layout } from 'antd'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  getApplications,
  getStatuses,
  setIsCreateApp,
  setApplications,
  setIsAppOpen,
  setAppId,
  setAppComments,
  getStatusesOnly,
  setStatusesOnly,
  setGetAppObj,
  setGetAppUrl,
} from '../../slices/applicationSlice'
import {
  getPersonal,
  getProducts,
  getSolutionMethods,
  setPersonal,
  setProducts,
  setSolutionMethods,
} from '../../slices/variableListSlice'
import Header from '../../components/Header'
import UserHeader from '../../components/UserHeader'
import { Role, roles } from '../../constants'
import Urls from '../../constants/urls'
import { useUserInfo } from '../../hooks/useUserInfo'
import ApplicationsField from '../../components/Applications/ApplicationsField'
import ApplicationMenu from '../../components/Applications/ApplicationMenu'

const { Footer, Content } = Layout

const ApplicationsPage = () => {
  const dispatch = useDispatch()
  const urls = Urls()
  const { userClientId, userPersId } = useUserInfo()
  const role = Role()
  const isClientRole = role === roles.client || role === roles.clientAdmin

  useEffect(() => {
    if (isClientRole) {
      const obj = {
        P_CLIENT_ID: userClientId,
      }
      dispatch(getApplications(urls.InWorkByClient, obj))
      dispatch(getStatuses(urls.GetStatList, obj))
    } else {
      const obj = {
        P_PERS_ID: userPersId,
      }
      dispatch(getApplications(urls.GetInWorkList, obj))
      dispatch(getStatuses(urls.GetStatList, obj))
    }
    dispatch(getStatusesOnly())
    dispatch(getProducts())
    dispatch(getPersonal())
    dispatch(getSolutionMethods())

    return function cleanUp() {
      dispatch(setIsCreateApp(false))
      dispatch(setApplications([]))
      dispatch(setIsAppOpen(false))
      dispatch(setAppId(0))
      dispatch(setAppComments([]))
      dispatch(setProducts([]))
      dispatch(setPersonal([]))
      dispatch(setStatusesOnly([]))
      dispatch(setGetAppObj({}))
      dispatch(setGetAppUrl(''))
      dispatch(setSolutionMethods([]))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Layout className="page-wrapper">
      <Header />
      <UserHeader />
      <Content className="wrapper" style={{ display: 'flex' }}>
        <ApplicationMenu />
        <ApplicationsField />
      </Content>
      <Footer className="wrapper">© 2022 "Астерус" все права защищены</Footer>
    </Layout>
  )
}

export default ApplicationsPage
