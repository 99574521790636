import { configureStore } from "@reduxjs/toolkit";
import userListReducer from "./slices/userListSlice"
import applicationReducer from "./slices/applicationSlice";
import settingReducer from "./slices/settingSlice"
import handbookReducer from "./slices/handbookSlice";
import variableListReducer from "./slices/variableListSlice";
import statisctisReducer from "./slices/statisctisSlice";

export const store = configureStore({
  reducer:{
    userList: userListReducer,
    application: applicationReducer,
    settings:settingReducer,
    handbook:handbookReducer,
    variableList: variableListReducer,
    statistics: statisctisReducer,
  }
})