import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'

export function confirm(title, obj, url, callback, postRequest) {
  Modal.confirm({
    title: title,
    icon: <ExclamationCircleOutlined />,
    okText: 'Подтвердить',
    okType: 'danger',
    cancelText: 'Отмена',
    onOk: () => {
      postRequest({
        url: url,
        data: obj,
        callback: callback,
      })
    },
  })
}
export function success(text, title) {
  Modal.success({
    content: text,
    title: title,
  })
}
export function error(text) {
  Modal.error({
    title: 'Ошибка',
    content: text,
  })
}

export const rules = [
  {
    required: true,
    message: 'Заполните поле ${label}!',
  },
]

export const filterHandbookValuesArray = (options, userOptions) => {
  const result = options.filter((option) => userOptions.includes(option.code))
  return result
}

export const productsConcatenation = (mainProduct, additionalProducts) => {
  return mainProduct + `${additionalProducts.length ? ',' + additionalProducts : ''}`
}

export const filterHandbookValuesString = (options, userOptions) => {
  const result = options.filter((region) =>
    userOptions.split(',').includes(String(region.code))
  )
  return result
}

export const regionsToNumber = (userRegions) => {
  return userRegions.split(',').map((region) => Number(region))
}
export const addMultipleSelectString = (string, newValue) => {
  let result = string.split(',')
  result.push(newValue)
  return result.join(',')
}
export const removeMultipleSelectString = (string, newValue) => {
  let result = string.split(',').filter((value) => value !== String(newValue))
  return result.join(',')
}
