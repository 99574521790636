import { createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { error } from '../constants/helpers'
import Urls from '../constants/urls'
import { setLoadingFalse, setLoadingTrue } from './variableListSlice'

const initialState = {
  statistic: [],
  period: '',
}

export const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    setStatistic: (state, action) => {
      state.statistic = action.payload
    },
    setPeriod: (state, action) => {
      const dateStrings = action.payload
      state.period = `с ${dateStrings[0]} по ${dateStrings[1]}`
    },
  },
})
//ACTIONS
export const { setStatistic, setPeriod } = statisticsSlice.actions

// selectors
export const selectStatistic = (state) => state.statistics.statistic
export const selectPeriod = (state) => state.statistics.period

// thunk action creators
export const getStatistics = (url, obj) => {
  return async (dispatch) => {
    try {
      dispatch(setLoadingTrue())
      const response = await axios.post(url, obj)
      const appData = await response.data.data
      appData.length > 0 ? dispatch(setStatistic(appData)) : error(response.data.message)
      await dispatch(setLoadingFalse())
    } catch (err) {
      throw new Error(err)
    }
  }
}

export const downloadStatistics = (obj) => {
  return async () => {
    try {
      const link = document.createElement('a')
      const url = `${Urls().GetStat}?p_date_from=${obj.P_DATE_FROM}&p_date_to=${
        obj.P_DATE_TO
      }&p_product=${obj.P_PRODUCT}&p_topic=${obj.P_TOPIC}`
      link.href = url
      link.download = 'Статистика за период ' + obj.P_DATE_FROM + '- ' + obj.P_DATE_TO
      link.click()
    } catch (err) {
      throw new Error(err)
    }
  }
}

export default statisticsSlice.reducer
