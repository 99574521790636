import './styles.css'
import { useDispatch, useSelector } from 'react-redux'
import {
  getFileComment,
  selectInformationTemplates,
} from '../../../slices/applicationSlice'
import { Comment } from 'antd'

const TemplatesForApplications = () => {
  const dispatch = useDispatch()
  const informationTemplates = useSelector(selectInformationTemplates)
  console.log(informationTemplates)

  return (
    <>
      <h3>Скачать шаблоны заявок для продукта ПРИС</h3>
      {informationTemplates.map((template) => (
        <Comment
          className="template"
          key={template.rn}
          content={
            <>
              <p className="comment-text">{template.message_txt}</p>
              {template.file_name && (
                <p
                  className="file-link"
                  onClick={() =>
                    dispatch(getFileComment(template.file_id, template.file_name))
                  }
                >
                  Скачать прикрепленный файл {template.file_name}
                </p>
              )}
            </>
          }
        />
      ))}
    </>
  )
}

export default TemplatesForApplications
